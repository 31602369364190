import Vue from "vue/dist/vue.esm";
import URI from "urijs";
import axios from "axios";
import { debounce } from "lodash";
import moment from "moment";
import ExchangeAmount from "../components/exchange_amount";
import ErrorsList from "../components/errors_list";
import Accordion from "../components/accordion";

import {
  sigFigs,
  currencyFormat,
  normalizeCurrency,
  nFormatter,
} from "../utils/formatting";
export function init() {
  const auctionFormApp = new Vue({
    components: {
      ExchangeAmount,
      ErrorsList,
      Accordion,
    },
    data: {
      auctionCurrency: null,
      refCurrency: null,
      auctionAmountInEstimateCurrency: null,
      auctionAmountEstimateCurrency: null,
      userAmountMinimumLimitInRefCurrency: null,
      userAmountMaximumLimitInRefCurrency: null,
      acceptedCurrencies: [],
      marginOrDiscount: null,
      minMarginOrDiscountLimit: null,
      maxMarginOrDiscountLimit: null,
      auctionLengthInSeconds: null,

      marginOrDiscountMin: null,
      marginOrDiscountMax: null,

      isForensicCheckEnabled: null,
      minBuyerFundsForensicRate: null,
      minBuyerFundsForensicRateMinimumLimit: null,
      minBuyerFundsForensicRateMaximumLimit: null,

      isSellingLimitsEnabled: false,
      sellingAmountMinimumLimitInEstimateCurrency: null,
      sellingAmountMaximumLimitInEstimateCurrency: null,
      sellingLimitsEstimateCurrency: null,

      buyingLimitsCoeff: null,

      currencyRatesBase: null,
      currencyRates: {},
      networkFee: NaN,
      networkCurrency: null,

      disableStepValidation: false,

      errors: {
        auctionCurrency: [],
        auctionAmountInEstimateCurrency: [],
        acceptedCurrencies: [],
        marginOrDiscount: [],
        minBuyerFundsForensicRate: [],
        sellingAmountMinimumLimitInEstimateCurrency: [],
        sellingAmountMaximumLimitInEstimateCurrency: [],
      },
      canUpdateErrors: false,
    },
    methods: {
      loadCurrencyRates: function() {
        const vm = this;

        axios.get("/api_v1/current_rates.json", {
          headers: {
            'Content-Type': "application/json; charset=utf-8"
          }
        })
        .then(function (response) {
          vm.currencyRatesBase = response.data.base;
          vm.currencyRates = response.data.rates;
        })
        .catch(function (error) {
          console.log('Error! Could not reach the API. ' + error)
        });
      },

      loadEstimateFee: function() {
        if (this.auctionCurrency == null) return false;

        const vm = this;

        let url = '/sell_offers/estimate_fee.json?';
        url += `currency=${this.auctionCurrency}`;
        url += `&amount=${this.auctionAmountInAuctionCurrency}`;
        if (!!this.isSellingLimitsEnabled) {
          if (!! this.sellingAmountMinimumLimitInEstimateCurrency && !!this.sellingAmountMaximumLimitInEstimateCurrency) {
            url += `&sell_limits=${!!this.isSellingLimitsEnabled}`;
            url += `&min_sell_amount=${this.sellingAmountMinimumLimitInEstimateCurrency}`;
            url += `&max_sell_amount=${this.sellingAmountMaximumLimitInEstimateCurrency}`;
            url += `&selling_limits_currency=${this.sellingLimitsEstimateCurrency}`;
          } else {
            url += `&sell_limits=false`;
          }
        } else {
          url += `&sell_limits=${!!this.isSellingLimitsEnabled}`;
        }

        vm.networkFee = NaN;
        $.ajax({
          url,
          type: "GET",
          timeout: 5000,
          success: function(response, textStatus) {
            if (!response.error) {
              vm.networkFee = parseFloat(response.estimate_fee);
              vm.networkCurrency = response.currency;
              vm.buyingLimitsCoeff = response.fee_coef;
            } else {
              vm.networkFee = NaN;
              vm.networkCurrency = null;
              vm.buyingLimitsCoeff = null;
            }
          },
        });
      },

      convert: function(amountFrom, currencyFrom, currencyTo) {
        const currencyFromNormalized = this.normalizeCurrency(currencyFrom);
        const currencyToNormalized = this.normalizeCurrency(currencyTo);
        return amountFrom*this.currencyRates[currencyFromNormalized]/this.currencyRates[currencyToNormalized];
      },
      
      switchAuctionAmountEstimateCurrency: function() {
        if (this.auctionAmountEstimateCurrency === this.refCurrency && this.isAuctionCurrencySet) {
          this.auctionAmountEstimateCurrency = this.auctionCurrency;
        } else {
          this.auctionAmountEstimateCurrency = this.refCurrency;
        }
        return true;
      },
      setAuctionAmountMinimumInEstimatedCurrency: function() {
        this.auctionAmountInEstimateCurrency = this.auctionAmountMinimumLimitInEstimateCurrency;
        return true;
      },
      setAuctionAmountMaximumInEstimatedCurrency: function() {
        this.auctionAmountInEstimateCurrency = this.auctionAmountMaximumLimitInEstimateCurrency;
        return true;
      },
      setToMinimumMarginOrDiscount: function() {
        this.marginOrDiscount = this.minMarginOrDiscountLimit;
      },
      setToNeutralMarginOrDiscount: function() {
        this.marginOrDiscount = 0;
      },
      setToMaximumMarginOrDiscount: function() {
        this.marginOrDiscount = this.maxMarginOrDiscountLimit;
      },
      switchSellingLimitsEstimateCurrency: function() {
        if (this.sellingLimitsEstimateCurrency === this.refCurrency && this.isAuctionCurrencySet) {
          this.sellingLimitsEstimateCurrency = this.auctionCurrency;
        } else {
          this.sellingLimitsEstimateCurrency = this.refCurrency;
        }
        return true;
      },
      toggleIsForensicCheckEnabled: function() {
        this.isForensicCheckEnabled = !this.isForensicCheckEnabled;
        return true;
      },
      toggleIsBuyingLimitsEnabled: function() {
        this.isSellingLimitsEnabled = !this.isSellingLimitsEnabled;
        return true;
      },
      hasErrors: function(key) {
        return this.errors[key].length > 0;
      },
      updateErrors: function(key) {
        if (!this.canUpdateErrors) return;

        let currencyFrom, currencyTo, estimatedAmount;

        if ( key === "auctionAmountInEstimateCurrency" ) {
          let errors = [];
          if ( this.isAuctionAmountSet ) {
            if ( this.canShowSellingAmountMinimumLimit && this.canShowSellingAmountMaximumLimit ) {
              currencyFrom = toCurrencyCode(this.sellingLimitsEstimateCurrency)[0];
              currencyTo = toCurrencyCode(this.auctionAmountEstimateCurrency)[0];
              estimatedAmount = this.convert(this.sellingAmountMaximumLimitInEstimateCurrency, currencyFrom, currencyTo)*(1-this.auctionAmountTolerance);

              if (this.isSellingLimitsEnabled && this.auctionAmountInEstimateCurrency < estimatedAmount) errors.push("can not be lower than max sell limit");
            }

            if ( !errors.length ) {
              if (this.auctionAmountInAuctionCurrency < this.auctionAmountMinimumLimitInAuctionCurrency) errors.push("can not be lower than account's minimum limit");
              if (this.auctionAmountInAuctionCurrency > this.auctionAmountMaximumLimitInAuctionCurrency) errors.push("can not be higher than account's maximum limit");
            }
          }

          this.errors.auctionAmountInEstimateCurrency = errors;
        } else if (key === "acceptedCurrencies") {
          let errors = [];
          if ( this.acceptedCurrencies.length > 0 ) {
            if (this.acceptedCurrencies.length > 10) errors.push("can't select more that 10 currencies");
          }

          this.errors.acceptedCurrencies = errors;
        } else if ( key === "marginOrDiscount" ) {
          let errors = [];
          if ( !isNaN(this.parsedMarginOrDiscount) ) {
            if (this.parsedMarginOrDiscount < this.marginOrDiscountMin) errors.push("must be greater than or equal to " + this.sigFigs(this.marginOrDiscountMin, 4));
            if (this.parsedMarginOrDiscount > this.marginOrDiscountMax) errors.push("must be lower than or equal to " + this.sigFigs(this.marginOrDiscountMax, 4));
          }

          this.errors.marginOrDiscount = errors;
        } else if ( key == "sellingAmountMinimumLimitInEstimateCurrency" ) {
          let errors = [];
          if ( this.isSellingLimitsEnabled ) {
            if ( this.canShowSellingAmountMinimumLimit ) {
              if (this.sellingAmountMinimumLimitInEstimateCurrency < this.sellingAmountMinimumLimitLowestInEstimateCurrency) errors.push("must be greater than or equal to " + this.sellingAmountMinimumLimitLowestInEstimateCurrency + " " + this.sellingLimitsEstimateCurrencyFormatted);
              if (this.sellingAmountMinimumLimitInEstimateCurrency > this.sellingAmountMinimumLimitHighestInEstimateCurrency) errors.push("must be lower than or equal to " + this.sellingAmountMinimumLimitHighestInEstimateCurrency + " " + this.sellingLimitsEstimateCurrencyFormatted);

              // if ( !errors.length  && this.sellingAmountMinimumLimitInEstimateCurrency > this.auctionAmountInEstimateCurrency) {
              //   errors.push("must be lower than or equal to selling amount");
              // }

              if ( !errors.length && this.canShowSellingAmountMaximumLimit && this.sellingAmountMinimumLimitInEstimateCurrency > this.sellingAmountMaximumLimitInEstimateCurrency) {
                errors.push("must be lower than or equal to max buy amount");
              }
            }
          }

          this.errors.sellingAmountMinimumLimitInEstimateCurrency = errors;
        } else if ( key == "sellingAmountMaximumLimitInEstimateCurrency" ) {
          let errors = [];
          if ( this.isSellingLimitsEnabled ) {
            if ( this.canShowSellingAmountMaximumLimit ) {
              if (this.sellingAmountMaximumLimitInEstimateCurrency < this.sellingAmountMaximumLimitLowestInEstimateCurrency) errors.push("must be greater than or equal to " + this.sellingAmountMaximumLimitLowestInEstimateCurrency + " " + this.sellingLimitsEstimateCurrencyFormatted);
              if (this.sellingAmountMaximumLimitInEstimateCurrency > this.sellingAmountMaximumLimitHighestInEstimateCurrency) errors.push("must be lower than or equal to " + this.sellingAmountMaximumLimitHighestInEstimateCurrency + " " + this.sellingLimitsEstimateCurrencyFormatted);

              // if ( !errors.length  && this.sellingAmountMaximumLimitInEstimateCurrency > this.auctionAmountInEstimateCurrency) {
              //   errors.push("must be greater than or equal to selling amount");
              // }

              if ( !errors.length && this.canShowSellingAmountMinimumLimit && this.sellingAmountMinimumLimitInEstimateCurrency > this.sellingAmountMaximumLimitInEstimateCurrency ) {
                errors.push("must be greater than or equal to min buy amount");
              }

              if ( !errors.length && this.canShowSellingAmountMinimumLimit && this.canShowSellingAmountMaximumLimit ) {
                currencyFrom = toCurrencyCode(this.sellingLimitsEstimateCurrency)[0];
                currencyTo = toCurrencyCode(this.auctionAmountEstimateCurrency)[0];
                estimatedAmount = this.convert(this.sellingAmountMaximumLimitInEstimateCurrency, currencyFrom, currencyTo)*(1-this.auctionAmountTolerance);

                if (this.auctionAmountInEstimateCurrency < estimatedAmount) errors.push("can not be higher than selling amount");
              }
            }
          }

          this.errors.sellingAmountMaximumLimitInEstimateCurrency = errors;
        } else if ( this.isForensicCheckEnabled && key == "minBuyerFundsForensicRate" ) {
          let errors = [];

          if (this.minBuyerFundsForensicRate < this.minBuyerFundsForensicRateMinimumLimit) errors.push("must be greater than or equal to " + this.minBuyerFundsForensicRateMinimumLimit);
          if (this.minBuyerFundsForensicRate > this.minBuyerFundsForensicRateMaximumLimit) errors.push("must be lower than or equal to " + this.minBuyerFundsForensicRateMaximumLimit);

          this.errors.minBuyerFundsForensicRate = errors;
        }
      },
      validateAndSubmit: function(event) {
        const e = event;
        const vm = this;

        this.disableStepValidation = true;

        if (vm.$el.querySelector("form").checkValidity()) {
          return true;
        } else {
          setTimeout(function() {
            vm.disableStepValidation = false;
          }, 100);
          return true;
        }
      },
      calculateInputStep: function(min, max) {
        if ( max == 0 ) {
          return "any";
        } else if ( max < 1 ) {
          return this.sigFigs(((max-min)/10),4);
        } else {
          return 1;
        }
      },
      ...{ sigFigs, currencyFormat, normalizeCurrency },
    },
    computed: {
      auctionAmountTolerance: function() {
        return 0.0005;
      },

      parsedAuctionAmountInEstimateCurrency: function() {
        return parseFloat(this.auctionAmountInEstimateCurrency);
      },

      parsedMarginOrDiscount: function() {
        return parseFloat(this.marginOrDiscount);
      },

      isCurrencyRatesLoaded: function() {
        return Object.keys(this.currencyRates).length !== 0;
      },

      isAuctionCurrencySet: function() {
        return typeof this.auctionCurrency === 'string';
      },

      isAuctionAmountSet: function() {
        return this.isAuctionCurrencySet && !isNaN(this.parsedAuctionAmountInEstimateCurrency);
      },

      auctionAmountEstimateCurrencyFormatted: function() {
        if (this.auctionAmountEstimateCurrency !== null) {
          return toCurrencyCode(this.auctionAmountEstimateCurrency)[0];
        } else {
          return null;
        }
      },

      auctionAmountEstimateCurrencyChangeTo: function() {
        if (this.auctionAmountEstimateCurrency !== null) {
          if (this.auctionAmountEstimateCurrency === this.refCurrency) {
            return this.auctionCurrency;
          } else {
            return this.refCurrency;
          }
        } else {
          return null;
        }
      },

      auctionAmountEstimateCurrencyChangeToFormatted: function() {
        if (this.auctionAmountEstimateCurrencyChangeTo !== null) {
          return toCurrencyCode(this.auctionAmountEstimateCurrencyChangeTo)[0];
        } else {
          return null;
        }
      },

      sellingLimitsEstimateCurrencyChangeTo: function() {
        if (this.sellingLimitsEstimateCurrency !== null) {
          if (this.sellingLimitsEstimateCurrency === this.refCurrency) {
            return this.auctionCurrency;
          } else {
            return this.refCurrency;
          }
        } else {
          return null;
        }
      },

      sellingLimitsEstimateCurrencyFormatted: function() {
        if (this.sellingLimitsEstimateCurrency !== null) {
          return toCurrencyCode(this.sellingLimitsEstimateCurrency)[0];
        } else {
          return null;
        }
      },

      sellingLimitsEstimateCurrencyChangeToFormatted: function() {
        if (this.sellingLimitsEstimateCurrencyChangeTo !== null) {
          return toCurrencyCode(this.sellingLimitsEstimateCurrencyChangeTo)[0];
        } else {
          return null;
        }
      },

      networkFeeFormatted: function() {
        if ( this.networkFee !== null && this.networkCurrency !== null ) {
          return this.sigFigs(this.networkFee, 4) + " " + this.currencyFormat(this.networkCurrency, false);
        } else {
          return "-";
        }
      },

      auctionAmountInAuctionCurrency: function() {
        if ( this.isAuctionAmountSet ) {
          if ( this.auctionAmountEstimateCurrency === this.refCurrency ) {
            const currencyFrom = toCurrencyCode(this.refCurrency)[0];
            const currencyTo = toCurrencyCode(this.auctionCurrency)[0];
            return this.sigFigs(this.convert(this.parsedAuctionAmountInEstimateCurrency, currencyFrom, currencyTo), 4);
          } else {
            return this.parsedAuctionAmountInEstimateCurrency;
          }
        } else {
          return NaN;
        }
      },

      expectedAmountInAuctionCurrency: function() {
        if ( this.isAuctionAmountSet ) {
          return this.auctionAmountInAuctionCurrency*(1+this.marginOrDiscount/100);
        } else {
          return NaN;
        }
      },

      auctionAmountMinimumLimitInRefCurrency: function() {
        if (!isNaN(this.parsedMarginOrDiscount) && this.parsedMarginOrDiscount < 0) {
          return this.userAmountMinimumLimitInRefCurrency / (1+this.parsedMarginOrDiscount/100);
        } else {
          return this.userAmountMinimumLimitInRefCurrency;
        }
      },

      auctionAmountMinimumLimitInRefCurrencyFormatted: function() {
        return this.sigFigs(this.auctionAmountMinimumLimitInRefCurrency, 4) ;
      },

      auctionAmountMaximumLimitInRefCurrency: function() {
        if (!isNaN(this.parsedMarginOrDiscount) && this.parsedMarginOrDiscount > 0) {
          return this.userAmountMaximumLimitInRefCurrency / (1+this.parsedMarginOrDiscount/100);
        } else {
          return this.userAmountMaximumLimitInRefCurrency;
        }
      },

      auctionAmountMaximumLimitInRefCurrencyFormatted: function() {
        return this.sigFigs(this.auctionAmountMaximumLimitInRefCurrency, 4) ;
      },

      auctionAmountMinimumLimitInAuctionCurrency: function() {
        if (this.isAuctionCurrencySet) {
          const currencyFrom = toCurrencyCode(this.refCurrency)[0];
          const currencyTo = toCurrencyCode(this.auctionCurrency)[0];
          return this.sigFigs(this.convert(parseFloat(this.auctionAmountMinimumLimitInRefCurrency), currencyFrom, currencyTo)*(1-this.auctionAmountTolerance), 4);
        } else {
          return 0;
        }
      },

      auctionAmountMinimumLimitInEstimateCurrency: function() {
        if (this.isAuctionCurrencySet) {
          if (this.auctionAmountEstimateCurrency === this.refCurrency) {
            return this.sigFigs(parseFloat(this.auctionAmountMinimumLimitInRefCurrency), 4);
          } else {
            return this.sigFigs(this.auctionAmountMinimumLimitInAuctionCurrency, 4);
          }
        } else {
          return 0;
        }
      },

      auctionAmountMinimumLimitInEstimateCurrencyFormatted: function() {
        if ( this.auctionAmountMinimumLimitInEstimateCurrency ) {
          return this.auctionAmountMinimumLimitInEstimateCurrency + " " + this.currencyFormat(this.auctionAmountEstimateCurrency, false);
        } else {
          return "-";
        }
      },

      auctionAmountMaximumLimitInAuctionCurrency: function() {
        if (this.isAuctionCurrencySet) {
          const currencyFrom = toCurrencyCode(this.refCurrency)[0];
          const currencyTo = toCurrencyCode(this.auctionCurrency)[0];
          return this.sigFigs(this.convert(parseFloat(this.auctionAmountMaximumLimitInRefCurrency), currencyFrom, currencyTo)*(1+this.auctionAmountTolerance),4);
        } else {
          return 0;
        }
      },

      auctionAmountStepInEstimateCurrency: function() {
        // if (this.disableStepValidation) {
          return "any";
        // } else {
        //   return this.calculateInputStep(this.auctionAmountMinimumLimitInEstimateCurrency, this.auctionAmountMaximumLimitInEstimateCurrency);
        // }
      },

      auctionAmountMaximumLimitInEstimateCurrency: function() {
        if (this.isAuctionCurrencySet) {
          if (this.auctionAmountEstimateCurrency === this.refCurrency) {
            return this.sigFigs(parseFloat(this.auctionAmountMaximumLimitInRefCurrency),4);
          } else {
            return this.sigFigs(this.auctionAmountMaximumLimitInAuctionCurrency,4);
          }
        } else {
          return 0;
        }
      },

      auctionAmountMaximumLimitInEstimateCurrencyFormatted: function() {
        if ( this.auctionAmountMaximumLimitInEstimateCurrency ) {
          return this.auctionAmountMaximumLimitInEstimateCurrency + " " + this.currencyFormat(this.auctionAmountEstimateCurrency, false);
        } else {
          return "-";
        }
      },

      canShowMarginOrDiscount: function() {
        return !isNaN(this.parsedMarginOrDiscount) && this.isAuctionCurrencySet;
      },

      canShowMinBuyerFundsForensicRate: function() {
        return  this.isForensicCheckEnabled &&
                this.minBuyerFundsForensicRate >= this.minBuyerFundsForensicRateMinimumLimit &&
                this.minBuyerFundsForensicRate <= this.minBuyerFundsForensicRateMaximumLimit;
      },

      auctionAmountInAuctionCurrencyFormatted: function() {
        if (!isNaN(this.auctionAmountInAuctionCurrency)) {
          const sellingCurrency = toCurrencyCode(this.auctionCurrency)[0];
          return this.sigFigs(this.auctionAmountInAuctionCurrency, 4) + " " + sellingCurrency;
        } else {
          return "-";
        }
      },

      acceptedCurrenciesFormatted: function() {
        if (this.acceptedCurrencies.length) {
          const vm = this;
          const formattedCurrencyCodes = this.acceptedCurrencies.map((code) => vm.currencyFormat(code) );
          return formattedCurrencyCodes.join("<br/>");
        } else {
          return "-";
        }
      },

      marginOrDiscountFormatted: function() {
        if (!isNaN(this.parsedMarginOrDiscount)) {
          return this.parsedMarginOrDiscount + "%";
        } else {
          return "-";
        }
      },

      marginatedAuctionCurrencyRateInRefCurrency: function() {
        const currencyFrom = toCurrencyCode(this.auctionCurrency)[0];
        const currencyTo = toCurrencyCode(this.refCurrency)[0];
        const unitAmount = this.convert(1, currencyFrom, currencyTo);
        return (1 + this.parsedMarginOrDiscount / 100) * unitAmount;
      },

      auctionLengthInSecondsFormatted: function() {
        return moment.duration({"seconds": this.auctionLengthInSeconds}).humanize();
      },

      canShowSellingAmountMinimumLimit: function() {
        return this.isSellingLimitsEnabled && this.isAuctionCurrencySet && this.sellingAmountMinimumLimitInEstimateCurrency != null && !isNaN(this.sellingAmountMinimumLimitInEstimateCurrency);
      },

      sellingAmountMinimumLimitFormatted: function() {
        if ( this.canShowSellingAmountMinimumLimit ) {
          return this.sellingAmountMinimumLimitInEstimateCurrency + " " + this.sellingLimitsEstimateCurrencyFormatted;
        } else {
          return NaN;
        }
      },

      canShowSellingAmountMaximumLimit: function() {
        return this.isSellingLimitsEnabled && this.isAuctionCurrencySet && this.sellingAmountMaximumLimitInEstimateCurrency != null && !isNaN(this.sellingAmountMaximumLimitInEstimateCurrency);
      },

      sellingAmountMaximumLimitFormatted: function() {
        if ( this.canShowSellingAmountMaximumLimit ) {
          return this.sellingAmountMaximumLimitInEstimateCurrency + " " + this.sellingLimitsEstimateCurrencyFormatted;
        } else {
          return NaN;
        }
      },

      sellingAmountMinimumLimitStepInEstimateCurrency: function() {
        // if (this.disableStepValidation) {
          return "any";
        // } else {
        //   return this.calculateInputStep(this.sellingAmountMinimumLimitLowestInEstimateCurrency, this.sellingAmountMinimumLimitHighestInEstimateCurrency);
        // }
      },

      sellingAmountMinimumLimitLowestInEstimateCurrency: function() {
        if (this.isAuctionCurrencySet) {
          if (this.sellingLimitsEstimateCurrency === this.refCurrency) {
            return this.sigFigs(this.auctionAmountMinimumLimitInRefCurrency, 4);
          } else {
            return this.sigFigs(this.auctionAmountMinimumLimitInAuctionCurrency, 4);
          }
        } else {
          return 0;
        }
      },

      sellingAmountMinimumLimitHighestInEstimateCurrency: function() {
        if (this.isAuctionCurrencySet) {
          if (this.sellingLimitsEstimateCurrency === this.refCurrency) {
            return this.sigFigs(this.auctionAmountMaximumLimitInRefCurrency, 4);
          } else {
            return this.sigFigs(this.auctionAmountMaximumLimitInAuctionCurrency, 4);
          }
        } else {
          return 0;
        }
      },

      sellingAmountMaximumLimitStepInEstimateCurrency: function() {
        // if (this.disableStepValidation) {
          return "any";
        // } else {
        //   return this.calculateInputStep(this.sellingAmountMaximumLimitLowestInEstimateCurrency, this.sellingAmountMaximumLimitHighestInEstimateCurrency);
        // }
      },

      sellingAmountMaximumLimitLowestInEstimateCurrency: function() {
        if (this.isAuctionCurrencySet) {
          if (this.sellingLimitsEstimateCurrency === this.refCurrency) {
            return this.sigFigs(this.auctionAmountMinimumLimitInRefCurrency, 4);
          } else {
            return this.sigFigs(this.auctionAmountMinimumLimitInAuctionCurrency, 4);
          }
        } else {
          return 0;
        }
      },

      sellingAmountMaximumLimitHighestInEstimateCurrency: function() {
        if (this.isAuctionCurrencySet) {
          if (this.sellingLimitsEstimateCurrency === this.refCurrency) {
            return this.sigFigs(this.auctionAmountMaximumLimitInRefCurrency, 4);
          } else {
            return this.sigFigs(this.auctionAmountMaximumLimitInAuctionCurrency, 4);
          }
        } else {
          return 0;
        }
      },

      buyingLimitsCoeffFormatted: function() {
        if (this.buyingLimitsCoeff) {
          return this.buyingLimitsCoeff;
        } else {
          return "..."
        }

      },

      offerType: function() {
        if (this.isSellingLimitsEnabled) {
          return "Take part";
        } else {
          return "Take all";
        }
      },
    },
    watch: {
      auctionAmountInEstimateCurrency: function(newVal) {
        this.updateErrors("auctionAmountInEstimateCurrency");
        this.updateErrors("sellingAmountMinimumLimitInEstimateCurrency");
        this.updateErrors("sellingAmountMaximumLimitInEstimateCurrency");

        this.debouncedLoadEstimateFee();
      },
      auctionCurrency: function(newVal) {
        if (this.auctionAmountEstimateCurrency !== this.refCurrency) {
          this.auctionAmountEstimateCurrency = newVal;
          if (this.sellingLimitsEstimateCurrency == null || this.sellingLimitsEstimateCurrency !== this.refCurrency) {
            this.sellingLimitsEstimateCurrency = newVal;
          }
          this.updateErrors("auctionAmountInEstimateCurrency");
          this.updateErrors("sellingAmountMinimumLimitInEstimateCurrency");
          this.updateErrors("sellingAmountMaximumLimitInEstimateCurrency");
        }

        this.debouncedLoadEstimateFee();
      },
      auctionAmountEstimateCurrency: function(newVal) {
        this.updateErrors("auctionAmountInEstimateCurrency");
        this.updateErrors("sellingAmountMinimumLimitInEstimateCurrency");
        this.updateErrors("sellingAmountMaximumLimitInEstimateCurrency");
      },
      acceptedCurrencies: function(newVal) {
        this.updateErrors("acceptedCurrencies");
      },
      marginOrDiscount: function(newVal) {
        this.updateErrors("marginOrDiscount");
        this.updateErrors("auctionAmountInEstimateCurrency");
        this.updateErrors("sellingAmountMinimumLimitInEstimateCurrency");
        this.updateErrors("sellingAmountMaximumLimitInEstimateCurrency");
      },
      minBuyerFundsForensicRate: function(newVal) {
        this.updateErrors("minBuyerFundsForensicRate");
      },
      isSellingLimitsEnabled: function(newVal) {
        this.updateErrors("auctionAmountInEstimateCurrency");
        this.updateErrors("sellingAmountMinimumLimitInEstimateCurrency");
        this.updateErrors("sellingAmountMaximumLimitInEstimateCurrency");

        this.debouncedLoadEstimateFee();
      },
      sellingLimitsEstimateCurrency: function(newVal) {
        this.updateErrors("auctionAmountInEstimateCurrency");
        this.updateErrors("sellingAmountMinimumLimitInEstimateCurrency");
        this.updateErrors("sellingAmountMaximumLimitInEstimateCurrency");
      },
      sellingAmountMinimumLimitInEstimateCurrency: function(newVal) {
        this.updateErrors("auctionAmountInEstimateCurrency");
        this.updateErrors("sellingAmountMinimumLimitInEstimateCurrency");
        this.updateErrors("sellingAmountMaximumLimitInEstimateCurrency");

        this.debouncedLoadEstimateFee();
      },
      sellingAmountMaximumLimitInEstimateCurrency: function(newVal) {
        this.updateErrors("auctionAmountInEstimateCurrency");
        this.updateErrors("sellingAmountMinimumLimitInEstimateCurrency");
        this.updateErrors("sellingAmountMaximumLimitInEstimateCurrency");

        this.debouncedLoadEstimateFee();
      },
    },
    mounted() {
      var vm = this;
      vm.loadCurrencyRates();

      this.$nextTick(() => {
        vm.canUpdateErrors = true;
      });

      initModalBlock();

      this.debouncedLoadEstimateFee = _.debounce(this.loadEstimateFee, 500);
    },
  });
  return { auctionFormApp };
}
