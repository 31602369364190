<template>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header font-20">
          <h5 class="modal-title">{{ header }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center pb50">
          <div class="bg-color-w bg-mobile-fix-lr p_30 p-sm-20 mb20">
            <img :src="require('images/new/profile-modal-check.svg')" v-show="successStatus" class="image"></img>
            <img :src="require('images/new/profile-modal-error.svg')" v-show="!successStatus" class="image"></img>
          </div>
          <div class="-no-fix-width -small-padding font-15 text-color-dark">
            {{ message }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'TopupRequestDetailsStatusModal',
  data: function() {
    return {
      header: "",
      message: "",
      successStatus: true
    }
  },
  methods: {
    setHeader: function(newHeader) {
      this.header = newHeader;
    },
    setMessage: function(newMessage) {
      this.message = newMessage;
    },
    setSuccessStatus: function(newSuccessStatus) {
      this.successStatus = newSuccessStatus;
    },
    openModal: function() {
      $(this.$el).modal('show');
    }
  },
}
</script>
