<template>
  <button
    v-if="canCreateOffer"
    type="button"
    class="g-fix-btn-width font-14 font-sm-14 trn btn btn-primary btn-color-bg -full-w -big-h rounded-pill"
    data-toggle="tooltip"
    :disabled="proceedDisabled"
    @click="proceedListener"
  >
    <span>Proceed</span>
  </button>
  <button
    v-else
    :class="
      'g-fix-btn-width font-14 font-sm-14 trn btn btn-outline-primary rounded-pill btn-color-bg' +
        [userExists ? '-l auction-buyout-btn -no-border disabled' : ' -orange']
    "
    data-toggle="tooltip"
    :title="disabledReason"
    @click="showLoginModel"
  >
    <span>Proceed</span>
  </button>
</template>

<script>
export default {
  name: "BuyoutProceedButton",
  props: {
    disabledReason: String,
    canCreateOffer: Boolean,
    userExists: Boolean,
    redirectTimeout: {
      type: Number,
      default: 2000,
      require: false,
    },
  },
  data: function () {
    return {
      proceedDisabled: false,
    }
  },
  methods: {
    displayParameter: function(url) {
      return typeof url === "undefined" || url == null ? "none" : "inline";
    },
    getErrorBody: function(error, proceedErrorUrl) {
      return `<div class="verif-block bg-color-pink br5 p_20 text-center mt30 font-16 font-sm-15 text-color-red">${error}
                <a href="${proceedErrorUrl}" style="display:
                ${this.displayParameter(proceedErrorUrl)}"
                class="text-color-blue underline-blue text-color-hover-blue -line offline-alert-setup-redirect">Proceed</a>
              </div>`;
    },
    showErrorDialog: function(error, proceedErrorUrl) {
      $("#errorModal").modal("show");
      $("#errorModal")
        .find(".redirect-message")
        .html(this.getErrorBody(error, proceedErrorUrl));
    },
    proceedResponse: function(type, redirectTo, error, proceedErrorUrl) {
      if (typeof type === "undefined") {
        this.proceedDisabled = false;
        this.showErrorDialog("Error happened. Try again!", proceedErrorUrl);
      } else if (type === "success") {
        $("#successModal").modal("show");
        setTimeout(function() {
          console.log(redirectTo);
          window.location.href = redirectTo;
        }, this.redirectTimeout);
      } else if (type === "error") {
        this.proceedDisabled = false;
        this.showErrorDialog(error, proceedErrorUrl);
      }
    },
    showLoginModel: function() {
      if (!this.userExists) {
        $("#loginModal").modal("show");
      }
    },
    proceedListener: function() {
      const vm = this;

      let form = $("#new_buyout_offer");

      if (form.valid()) {
        this.proceedDisabled = true;

        setTimeout(function () {
          $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            dataType: "JSON",
            async: false,
            success: function(response, textStatus) {
              vm.proceedResponse(response.type, response.redirect_to, response.error, response.proceed_error);
            }.bind(this),
            error: function(xhr, status) {
              vm.proceedDisabled = false;
              alert("Error: " + errorThrown);
            },
          }, 0);
        });
      }
    },
  },
};
</script>
