<template>
  <div class="row pt-0 pb-0 margins-card">
    <div class="col-12 col-md-9 pay-card-l pt-4 pb-4 pl-4 pr-4">
      <div class="font-20 font-sm-18 mb-card">Transaction info</div>

      <div class="row mb-card">
        <div class="col-12 font-14 font-bold">You sent</div>
        <div class="col-12 font-22 font-sm-17 font-bold">
          <strong>{{ sigFigs(amountSent, 4) }}</strong>
          <strong v-html="currencyFormat(currencySent)"></strong>
        </div>
      </div>

      <div class="row mb-card">
        <div class="col-12 font-14 font-bold">You received</div>
        <div class="col-12 font-22 font-sm-17 font-bold">
          <strong>{{ sigFigs(amountReceived, 4) }}</strong>
          <strong v-html="currencyFormat(currencyReceived)"></strong>
        </div>
      </div>

      <div class="row">
        <div class="col-12 font-14 font-bold">Transaction hash</div>

        <div class="col-12 font-22 font-bold break-word" v-if="transactionLinkReceivedAvailable">
          <div class="font-22 font-sm-17 d-inline-block mr-1 anywhere">
            <div class="ellipsis-one-lines">{{ transactionHashReceived }}</div>
          </div>
        </div>

        <div class="col-12" :class="{ 'fade-in': transactionLinkReceivedAvailable }" v-if="transactionLinkReceivedAvailable">
          <button class="qr-copy mr-3" @click="copyToClipboard" :data-copy="transactionHashReceived">
            <img :src="require('images/new/copy-icon.svg')" class="w-100 copy-blue"></img>
            <img :src="require('images/new/copy-icon-hover.svg')" class="w-100 hovered copy-black"></img>
            <img :src="require('images/new/checkmark-blue.svg')" class="w-100 checkmark-blue" style="display: none;"></img>
          </button>
          <a :href="transactionLinkReceived" target="_blank" class="qr-copy mr-3">
            <img :src="require('images/new/ext-link.svg')" class="w-100"></img>
            <img :src="require('images/new/ext-link-hover.svg')" class="w-100 hovered"></img>
          </a>
        </div>

        <div class="col-12 font-22 d-flex" :class="{ 'fade-in': !transactionLinkReceivedAvailable }" v-if="!transactionLinkReceivedAvailable">
          <div class="deal-grid-row--address font-weight-normal font-italic font-20 font-sm-17 text-color-grey d-inline-block mr-2">
            {{ transactionHashReceivedStatus }}
          </div>
        </div>

      </div>
    </div>

    <div class="col-12 col-md-3 bg-grey pay-card-r bg-light-grey pt-3 pb-3">
      <div class="row h-100 align-items-center">
        <div class="col">
          <div class="row mt-3 mb-3">
            <div class="col-12 text-center mb-2">
              <img :src="require('images/new/load-icon-big.svg')" class="load-icon-big" v-show="transactionStatusPending"></img>
              <img :src="require('images/new/success-icon.svg')" :class="{ 'fade-in': transactionStatusSucess }" v-show="transactionStatusSucess"></img>
              <img :src="require('images/new/failure-icon.svg')"  :class="{ 'fade-in': transactionStatusFailed }" v-show="transactionStatusFailed"></img>
            </div>
            <div class="col-12 font-16 font-sm-15 text-center">{{ transactionStatusSent }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sigFigs, currencyFormat } from "utils/formatting";
import { copyToClipboard } from "utils/clipboard";

export default {
  name: 'DealApproveDetails',
  components: {
  },
  props: {
    title: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      required: false,
      default: null
    },
    statusSent: {
      type: String,
      required: true
    },
    amountSent: {
      type: Number,
      required: true
    },
    currencySent: {
      type: String,
      required: true
    },
    blockchainSent: {
      type: String,
      required: true
    },
    statusReceived: {
      type: String,
      required: true
    },
    amountReceived: {
      type: Number,
      required: true
    },
    currencyReceived: {
      type: String,
      required: true
    },
    blockchainReceived: {
      type: String,
      required: true
    },
    transactionHashReceived: {
      type: String,
      required: false,
      default: null
    },
  },
  data: function() {
    return {
    }
  },
  methods: {
    copyToClipboard: function(e) {
      const $el = $(e.currentTarget);
      const $copyEl = $el.find(".copy-blue");
      const $copyElHover = $el.find(".copy-black");
      const $checkmarkEl = $el.find(".checkmark-blue");

      copyToClipboard($el.data("copy"));

      const delay = 300;
      $copyElHover.hide();
      $copyEl.fadeOut(delay, function() {
        $checkmarkEl.fadeIn(delay);

        setTimeout(function() {
          $checkmarkEl.fadeOut(delay, function() {
            $copyEl.fadeIn(delay);
            $copyElHover.css('display', '');
          });
        }, 1000);
      });
    },

    pageErrorMessage: function(message) {
      this.$root.$refs.statusModal.setHeader("An error occured...");
      this.$root.$refs.statusModal.setMessage(message);
      this.$root.$refs.statusModal.setSuccessStatus(false);
      this.$root.$refs.statusModal.openModal();
    },

    pageSuccessMessage: function(message) {
      this.$root.$refs.statusModal.setHeader("Success");
      this.$root.$refs.statusModal.setMessage(message);
      this.$root.$refs.statusModal.setSuccessStatus(true);
      this.$root.$refs.statusModal.openModal();
    },

    ...{ sigFigs, currencyFormat }
  },
  computed: {
    hasHint: function() {
      return typeof this.hint == "string" && this.hint != "";
    },

    transactionStatusPending: function() {
      const statuses = [
        'pending_for_remote_transaction_init',
        'pending_for_remote_approval',
        'pending_for_service_approval',
        'signature_ready',
        'escrow_transaction_broadcasted'
      ];

      return statuses.includes(this.statusSent);
    },

    transactionStatusSucess: function() {
      const statuses = ['escrow_transaction_ready'];

      return statuses.includes(this.statusSent);
    },

    transactionStatusFailed: function() {
      const statuses = ['escrow_transaction_rejected', 'escrow_transaction_expired', 'escrow_transaction_failed'];

      return statuses.includes(this.statusSent);
    },

    transactionStatusSent: function() {
      const otherPartySide = this.$parent.partySide == 'seller' ? 'buyer' : 'seller';

      const statusToHeader = {
        'pending_for_remote_transaction_init': 'Generating transaction...',
        'pending_for_remote_approval': (
          this.$parent.userAutoapprovalEnabled ?
            'Just wait...' :
            'Go to WH Cypher and approve'
          ),
        'pending_for_service_approval': `Pending for ${otherPartySide}...`,
        'signature_ready': 'All done! Finishing...',
        'escrow_transaction_broadcasted': 'Waiting for miners...',
        'escrow_transaction_ready': 'Success!',
        'escrow_transaction_rejected': 'Rejected',
        'escrow_transaction_expired': 'Expired',
        'escrow_transaction_failed': 'Failed',
      }
      return statusToHeader[this.statusSent];
    },

    transactionLinkReceivedAvailable: function() {
      return !!this.transactionHashReceived;
    },

    transactionLinkReceived: function() {
      let referenceLink;

      if (this.blockchainReceived == "Bitcoin") {
        referenceLink = "https://live.blockcypher.com/btc/tx/";
      } else if (this.blockchainReceived == "BitcoinTestnet") {
        referenceLink = "https://live.blockcypher.com/btc-testnet/tx/";
      } else if (this.blockchainReceived == "Litecoin") {
        referenceLink = "https://live.blockcypher.com/ltc/tx/";
      } else if (this.blockchainReceived == "Dogecoin") {
        referenceLink = "https://live.blockcypher.com/doge/tx/";
      } else if (this.blockchainReceived == "BitcoinCash") {
        referenceLink = "https://blockchair.com/bitcoin-cash/";
      } else if (this.blockchainReceived == "Ethereum") {
        referenceLink = "https://etherscan.io/tx/";
      } else if (this.blockchainReceived == "EthereumRopsten") {
        referenceLink = "https://ropsten.etherscan.io/tx/";
      } else if (this.blockchainReceived == "EthereumGoerli") {
        referenceLink = "https://goerli.etherscan.io/tx/";
      } else if (this.blockchainReceived == "BinanceSmartChain") {
        referenceLink = "https://bscscan.com/tx/";
      } else if (this.blockchainReceived == "Polygon") {
        referenceLink = "https://polygonscan.com/tx/";
      } else if (this.blockchainReceived == "Avalanche") {
        referenceLink = "https://snowtrace.io/tx/";
      }

      referenceLink = referenceLink + this.transactionHashReceived;

      return referenceLink;
    },

    transactionHashReceivedStatus: function() {
      const statusToHeader = {
        'pending_for_remote_transaction_init': 'Will be available shortly',
        'pending_for_remote_approval': 'Will be available shortly',
        'pending_for_service_approval': 'Will be available shortly',
        'signature_ready': 'Will be available shortly',
        // 'escrow_transaction_broadcasted': 'Will be available shortly',
        'escrow_transaction_rejected': 'Not available',
        'escrow_transaction_expired': 'Not available',
        'escrow_transaction_failed': 'Not availabley',
      };

      return statusToHeader[this.statusReceived];
    },

  },
  mounted() {

  }
}
</script>

<style scoped>
.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
