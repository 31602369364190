import Vue from 'vue/dist/vue.esm';
import TsWalletBalance from '../components/ts_wallet_balance';
import ResignAddressButton from '../components/resign_address_button';
import AddressValidationStatusShield from '../components/address_validation_status_shield.vue';

export function init() {
    const tsWalletsApp = new Vue({
        components: {
            TsWalletBalance,
            ResignAddressButton,
            AddressValidationStatusShield,
        },
    });
    return { tsWalletsApp }
}
