import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import CountdownLight from "../components/countdown_light";
import DealRateStars from "../components/deal_rate_stars";
import DealApproveDetails from '../components/deal_approve_details';
import WhcypherApproveGuidanceModal from '../components/whcypher_approve_guidance_modal';

import {
  sigFigs,
  currencyFormat,
  normalizeCurrency,
  nFormatter,
} from '../utils/formatting'

export function init() {
    const dealPageApp = new Vue({
        components: {
          CountdownLight,
          DealRateStars,
          DealApproveDetails,
          WhcypherApproveGuidanceModal,
        },
        data: {
          dealFuzzyId: null,
          transactionFuzzyId: null,
          partyId: null,
          partySide: "seller",
          auctionFuzzyId: null,
          accountPubKey: null,
          transactionStatus: null,
          transactionDetails: {},
          opponentTransactionStatus: null,
          opponentTransactionDetails: {},
          userDetails: {},

          currencyRatesBase: null,
          currencyRates: {},
          ratesLoaded: false,
        },
        methods: {
          convert: function(amountFrom, currencyFrom, currencyTo) {
            const currencyFromNormalized = this.normalizeCurrency(currencyFrom);
            const currencyToNormalized = this.normalizeCurrency(currencyTo);
            return amountFrom*this.currencyRates[currencyFromNormalized]/this.currencyRates[currencyToNormalized];
          },

          loadCurrencyRates: function() {
            const vm = this;

            axios.get("/api_v1/current_rates.json", {
              headers: {
                'Content-Type': "application/json; charset=utf-8"
              }
            })
            .then(function (response) {
              vm.currencyRatesBase = response.data.base;
              vm.currencyRates = response.data.rates;
              vm.ratesLoaded = true;
            })
            .catch(function (error) {
              console.log('Error! Could not reach the API. ' + error)
            })
          },

          checkTransactionStatus: function() {
            const vm = this;

            $.ajax({
              url: `/deal_transactions/${this.transactionFuzzyId}/state.json`,
              type: "GET",
              timeout: 5000,
              success: function(response, textStatus) {
                vm.transactionStatus = response.transaction_details.state;
                vm.transactionDetails = response.transaction_details;

                vm.opponentTransactionStatus = response.opponent_transaction.state;
                vm.opponentTransactionDetails = response.opponent_transaction;

                vm.userDetails = response.sender_autoapprove;
              },
            });
          },

          openWhcypherApproveGuidanceModal: function(additionalStep) {
            this.$refs.whcypherApproveGuidanceModal.openModal(additionalStep);
          },

          ...{ sigFigs, currencyFormat, normalizeCurrency, nFormatter }
        },
        computed: {
          dataLoaded: function() {
            return !!this.transactionStatus;
          },

          counterAvailable: function() {
            return this.dataLoaded && !!this.transactionDetails.expires_at;
          },

          transactionApprovalFooter: function() {
            const statuses = [
              'pending_for_remote_transaction_init',
              'pending_for_remote_approval',
            ];

            return statuses.includes(this.transactionStatus);
          },

          transactionButtonActive: function() {
            return this.transactionStatus == 'pending_for_remote_approval';
          },

          transactionWaitingFooter: function() {
            const statuses = [
              'pending_for_service_approval',
              'signature_ready',
              'escrow_transaction_broadcasted',
            ];

            return statuses.includes(this.transactionStatus);
          },

          transactionSuccessFooter: function() {
            return this.transactionStatus == 'escrow_transaction_ready';
          },

          transactionFailureFooter: function() {
            const statuses = [
              'escrow_transaction_rejected',
              'escrow_transaction_expired',
              'escrow_transaction_failed',
            ];

            return statuses.includes(this.transactionStatus);
          },

          transactionWillExpire: function() {
            const statuses = [
              'pending_for_remote_transaction_init',
              'pending_for_remote_approval',
              'pending_for_service_approval',
              'signature_ready',
            ];

            return !!this.transactionDetails.expires_at && statuses.includes(this.transactionStatus);
          },

          screenTitle: function() {
            const otherPartySide = this.partySide == 'seller' ? 'buyer' : 'seller';

            const statusToHeader = {
              'pending_for_remote_transaction_init': 'Transaction approval',
              'pending_for_remote_approval': 'Transaction approval',
              'pending_for_service_approval': `Transaction approval`,
              'signature_ready': 'Trade almost ready',
              'escrow_transaction_broadcasted': 'Trade almost ready',
              'escrow_transaction_ready': 'Trade is sucessfull!',
              'escrow_transaction_rejected': 'Trade rejected',
              'escrow_transaction_expired': 'Trade failed',
              'escrow_transaction_failed': 'Trade rejected',
            }
            return statusToHeader[this.transactionStatus];
          },

          userWalletOnline: function() {
            try {
              const half_minute = 15000;
              return Date.parse(this.userDetails.last_seen) > Date.now() - half_minute;
            } catch {
              return false;
            }
          },

          userMaxApproveAmountInUSD: function() {
            if ( this.userDetails.amount_currency == "USD" || this.userDetails.amount_coins ) {
              return this.userDetails.amount_coins;
            } else {
              return 0;
            }
          },

          userDealAmountInUSD: function() {
            if ( this.userDetails.amount_currency ) {
              const currencyFrom = toCurrencyCode(this.transactionDetails.currency_code)[0];
              const currencyTo = toCurrencyCode(this.userDetails.amount_currency)[0];
              return this.convert(this.transactionDetails.amount_coins, currencyFrom, currencyTo);
            } else {
              return 0;
            }
          },

          userAutoapprovalEnabled: function() {
            return this.userWalletOnline && this.userMaxApproveAmountInUSD > this.userDealAmountInUSD;
          },
        },

        watch: {
          transactionButtonActive: function(newValue) {
            if (!newValue) {
              this.$refs.whcypherApproveGuidanceModal.closeModal();
            }
          }
        },

        mounted() {
          this.loadCurrencyRates();
          this.checkTransactionStatus();
          setInterval(this.checkTransactionStatus.bind(this), 10000);
        }
    });
    return { dealPageApp }
}
