/* eslint no-console: 0 */
import Vue from 'vue/dist/vue.esm'
import { Plugin } from 'vue-fragment';

// Enables v-fragment
Vue.use(Plugin);

// https://gist.github.com/drozdzynski/de1584e3e16ea27f51798924b7da18a8
Vue.directive('init', {
  bind (el, binding, vnode, oldVnode) {
    var model = vnode.data.directives.find(function(dir) { return dir.name === 'model' });
    if (!model || !model.expression) {
      console.error('[vue-set-value warn] The element does not have v-model');
      return;
    }
    const expressions = model.expression.split(".");
    let context = vnode.context;
    for(let i = 0; i < (expressions.length-1); i++) {
      context = context[expressions[i]]
    }
    context[expressions[expressions.length-1]] = binding.value;
  }
});

document.addEventListener('DOMContentLoaded', () => {
  // if (document.getElementById('login_button_wrapper')) {
  //   const loginButton = require('../apps/login_button');
  //   const { loginButtonApp } = loginButton.init();
  //   loginButtonApp.$mount('#login_button_wrapper');
  // }

  if (document.getElementById('search-results')) {
    const searchPage = require('../apps/search_page');
    const { searchPageApp } = searchPage.init();
    searchPageApp.$mount('#search-results');
  }

  if (document.getElementById('auction_form_wrapper')) {
    const auctionForm = require('../apps/auction_form.js');
    const { auctionFormApp } = auctionForm.init();
    auctionFormApp.$mount('#auction_form_wrapper');
  }

  if (document.getElementById('destinations_form_wrapper')) {
    const destinationsForm = require('../apps/destinations_form.js');
    const { destinationsFormApp } = destinationsForm.init();
    destinationsFormApp.$mount('#destinations_form_wrapper');
  }

  if (document.getElementById('topup_requests_form_wrapper')) {
    const topupRequestsForm = require('../apps/topup_requests_form.js');
    const { topupRequestsFormApp } = topupRequestsForm.init();
    topupRequestsFormApp.$mount('#topup_requests_form_wrapper');
  }

  if (document.getElementById('auction_single_page_wrapper')) {
    const auctionSinglePage = require('../apps/auction_single_page.js');
    const { auctionSinglePageApp } = auctionSinglePage.init();
    auctionSinglePageApp.$mount('#auction_single_page_wrapper');
  }

  if (document.getElementById('withdrawal_form_wrapper')) {
    const withdrawalForm = require('../apps/withdrawal_form.js');
    const { withdrawalFormApp } = withdrawalForm.init();
    withdrawalFormApp.$mount('#withdrawal_form_wrapper');
  }

  if (document.getElementById('ts_wallets_wrapper')) {
    const tsWallets = require('../apps/ts_wallets.js');
    const { tsWalletsApp } = tsWallets.init();
    tsWalletsApp.$mount('#ts_wallets_wrapper');
  }

  if (document.getElementById('deal_wrapper')) {
    const dealPage = require('../apps/deal_page.js');
    const { dealPageApp } = dealPage.init();
    dealPageApp.$mount('#deal_wrapper');
  }
})
