<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">

        <div class="modal-content modal-place-offer-step-3 p_30 d-block">
          <div class="modal-header -bb">
            <div class="font-20 font-sm-18 font-bold text-color-dark pl30 col text-center redirect-message-header">Wait</div>
          </div>
          <div class="modal-body text-center pb50">

            <img :src="require('images/new/profile-modal-error.svg')" class="banner image-error" ></img>
            <p class="font-19 font-sm-16 text-color-dark mt20 redirect-message">
              We can't validate a signature on this deposit wallet.
            </p>
            <p class="text-color-red mt8 text-bold font-17 font-sm-12">
              {{ destination }}
            </p>
            <p class="font-19 font-sm-16 text-color-dark mt30 redirect-message">
              Please resign or contact support with reference ID:
              <div class="row justify-content-md-center mt12 cursor-point">
                <div class="col-auto font-19 font-sm-16 p-1">
                  <strong>{{ walletId }}</strong>
                </div>
                <div class="col-auto p-1">
                  <button class="qr-copy mr-3" @click="copyWalletIdToClipboard">
                    <img :src="require('images/new/copy-icon.svg')" class="w-100 copy-blue"></img>
                    <img :src="require('images/new/copy-icon-hover.svg')" class="w-100 hovered copy-black"></img>
                    <img :src="require('images/new/checkmark-blue.svg')" class="w-100 checkmark-blue" style="display: none;"></img>
                  </button>
                </div>
              </div>
            </p>

            <div class="row mt40">
              <div class="col">
                <resign-address-button
                  style="font-size:19px!important"
                  :wallet-id="walletId"
                  :signature="signature"
                  :blockchain="blockchain"
                  :destination="destination"
                  :public-key="publicKey"
                ></resign-address-button>
              </div>
              <div class="col">
                <a href="https://t.me/whalesheaven" class="font-19 text-color-blue text-color-hover-blue -line offline-alert-setup-redirect" target='_blank'>Contact us</a>
              </div>
            </div>

            <div class="mt40" v-show="hasErrorMessage">
              <div class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ec } from "elliptic";
import { sha256 } from "js-sha256";
import { eventBus } from "../utils/event_bus.js";

import ResignAddressButton from '../components/resign_address_button';

import { copyToClipboard } from "utils/clipboard";

export default {
  name: "VerifySignature",
  components: {
    ResignAddressButton,
  },
  props: {
    walletId: {
      type: String,
      required: true,
    },
    blockchain: {
      type: String,
      required: true,
    },
    publicKey: {
      type: String,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    },
    signature: {
      type: String,
      required: true,
    },
  },
  data: function() {
    return {
      errorMessage: "",
      modalOpened: false,
    };
  },
  computed: {
    hasErrorMessage: function() {
      return this.errorMessage != "";
    },
  },
  methods: {
    openErrorModal: function() {
      this.modalOpened = true;
      this.$nextTick(() => {
        $(this.$el).find('.modal').modal('show');
      });
    },

    closeErrorModal: function() {
      this.modalOpened = false;
      this.$nextTick(() => {
        $(this.$el).find('.modal').modal('hide');
      });
    },

    setErrorMessage: function(newErrorMessage) {
      this.errorMessage = newErrorMessage;
    },

    copyWalletIdToClipboard: function(e) {
      const $el = $(e.currentTarget);
      const $copyEl = $el.find(".copy-blue");
      const $copyElHover = $el.find(".copy-black");
      const $checkmarkEl = $el.find(".checkmark-blue");

      this.copyToClipboard(this.walletId);

      const delay = 300;
      $copyElHover.hide();
      $copyEl.fadeOut(delay, function() {
        $checkmarkEl.fadeIn(delay);

        setTimeout(function() {
          $checkmarkEl.fadeOut(delay, function() {
            $copyEl.fadeIn(delay);
            $copyElHover.css('display', '');
          });
        }, 1000);
      });
    },

    ...{ copyToClipboard },
  },
  mounted() {
    const vm = this;
    vm.currentSignature = vm.signature;
  },
};
</script>
