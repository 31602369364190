<template>
  <div>
    <slot v-if="showDefaultResults"></slot>
    <search-result v-for="result in results" :key="result.fuzzy_id" :result="result" />
  </div>
</template>

<script>
import URI from 'urijs';
import axios from 'axios';
import { debounce } from 'lodash';

import SearchResult from './search_result'

import { currencyFormat } from "utils/formatting"

const pluralize = require('pluralize');

export default {
  name: 'SearchResults',
  components: {
    SearchResult,
  },
  props: {
    showDefaultResults: {
      type: Boolean,
      required: true,
      default: false
    },
    auctionLotCurrency: {
      type: Array,
      required: true
    },
    auctionLotAcceptedCurrencies: {
      type: Array,
      required: true
    },
    auctionLotOfferTypes: {
      type: Array,
      required: true
    },
    initialZeroResults: {
      type: Boolean,
      required: true,
      default: false
    },
    sorting: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      results: [],
      searchFormUpdated: false,
      currentPage: 1,
    }
  },
  methods: {
    newSearch: function() {
      const vm = this;
      vm.nullifySearchState();

      const form = vm.$root.$el.querySelector('form');
      const formData = new FormData(form);
      const params = new URLSearchParams(formData).toString();
      const state = Object.fromEntries(formData);
      const historyUrl = '/marketplace?' + params;
      const requestUrl = '/marketplace.json?' + params;

      axios.get(requestUrl, {
        headers: {
          'Content-Type': "application/json; charset=utf-8"
        }
      })
      .then(function (response) {
        window.history.pushState(state, '', historyUrl);
        vm.currentPage = 1;

        vm.results = response.data.results;
        const similarSearchesPlaceholder = vm.$root.$el.querySelector('.recent_similar_queries_count');
        similarSearchesPlaceholder.innerHTML = `${response.data.similar_searches} ${pluralize('query', response.data.similar_searches)} in last 7 days`;
        vm.$root.setZeroResults(!vm.results.length);
        vm.$nextTick(function() {
          loadAvatars('.user_avataaar');
        });
        vm.$root.setLoadingResults(false);

        Object.entries(response.data.filters).map(filter => {
          let select = form.querySelector('select[name="f['+filter[0]+'][]"]');
          select.innerHTML = '';

          // <option value="ETH:ROPSTEN" selected="selected" data-subtext="Ether" title="Ether" data-selected-content="<span class='selected-content'><span class='curr-format'>ETH<span class='supsub'><sub class='subscript'>ROPSTEN</sub></span></span></span>" data-thumbnail="/uploads/supported_token/icon/xsmall_ETH.png">ETH</option>


          filter[1].forEach(coin_group => {
            let optgroup = document.createElement('optgroup');
            optgroup.setAttribute('label', coin_group.group_name);

            coin_group.options.forEach(currency => {
              let option = document.createElement('option');
              option.value = currency.code;
              option.setAttribute('title', currency.name);
              option.setAttribute('data-selected-content', `<span class='selected-content'>${currencyFormat(currency.code)}</span>`);
              option.setAttribute('data-subtext', currency.name);
              option.setAttribute('data-thumbnail', currency.icon_url);
              option.text = currency.ticker;
              option.selected = currency.selected;

              optgroup.appendChild(option);
            });
            select.appendChild(optgroup);
          })

          $(select).selectpicker('refresh');
        })

      })
      .catch(function (error) {
        console.log('Error! Could not reach the API. ' + error)
      })
    },
    loadMore: function() {
      const vm = this;
      vm.$root.setLoadingResults(true);

      axios.get(this.nextPageUrl(), {
        headers: {
          'Content-Type': "application/json; charset=utf-8"
        }
      })
      .then(function (response) {
        vm.currentPage = vm.currentPage + 1;
        vm.$root.setLoadingResults(false);

        if ( response.data.results.length ) {
          [].push.apply(vm.results, response.data.results);
          vm.$nextTick(function() {
            loadAvatars('.user_avataaar');
          });
        } else {
          vm.$root.setNoMoreResults(true);
        }
      })
      .catch(function (error) {
        console.log('Error! Could not reach the API. ' + error)
      })
    },
    nullifySearchState: function() {
      this.currentPage = 1;
      this.$root.setNoMoreResults(false);
      this.$root.setLoadingResults(true);
    },
    nextPageUrl: function() {
      const form = this.$root.$el.querySelector('form');
      const formData = new FormData(form);
      const params = new URLSearchParams(formData).toString();
      const state = Object.fromEntries(formData);
      const requestUrl = '/marketplace.json?' + params;
      const nextPage = this.currentPage + 1;

      var nextUri = URI(requestUrl);
      nextUri.removeSearch('page');
      nextUri.addSearch('page', nextPage);

      return nextUri.toString();
    }
  },
  watch: {
    auctionLotCurrency: function () {
      if (this.$root.searchFormInitialized) {
        this.debouncedNewSearch();
      }
    },
    auctionLotAcceptedCurrencies: function () {
      if (this.$root.searchFormInitialized) {
        this.debouncedNewSearch();
      }
    },
    auctionLotOfferTypes: function () {
      if (this.$root.searchFormInitialized) {
        this.debouncedNewSearch();
      }
    },
    sorting: function () {
      if (this.$root.searchFormInitialized) {
        this.debouncedNewSearch();
      }
    }
  },
  mounted() {
    var vm = this;

    vm.$root.setZeroResults(vm.initialZeroResults);
    this.debouncedNewSearch = _.debounce(this.newSearch, 500);

    vm.$nextTick(function() {
      vm.$root.searchFormReady();
    });
  }
}
</script>
