<template>
  <span class="currency-exchange-analogus">
    {{ refAmountFormatted }}
  </span>
</template>

<script>
import { sigFigs, currencyFormat } from "utils/formatting"

export default {
  name: 'ExchangeAmount',
  props: {
    amount: {
      validator: prop => typeof prop === 'string' || typeof prop === 'number' || prop === null
    },
    currency: {
      validator: prop => typeof prop === 'string' || prop === null
    },
    refCurrency: {
      validator: prop => typeof prop === 'string' || prop === null
    },
    areRatesAvailable: {
      type: Boolean,
      default: false
    },
    canShow: {
      type: Boolean,
      default: true
    },
    approxSign: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    convert: function(...args) {
      return this.$parent.convert(...args);
    }
  },
  computed: {
    canShowRefAmount: function() {
      return  typeof this.areRatesAvailable &&
              typeof this.currency === 'string' &&
              typeof this.refCurrency === 'string' &&
              !isNaN(this.parsedAmount);
    },
    parsedAmount: function() {
      return parseFloat(this.amount);
    },
    refAmount: function() {
      if ( this.canShowRefAmount && this.canShow ) {
        const currencyFrom = toCurrencyCode(this.currency)[0];
        const currencyTo = toCurrencyCode(this.refCurrency)[0];
        return this.convert(this.parsedAmount, currencyFrom, currencyTo);
      } else {
        return NaN;
      }
    },
    refAmountFormatted: function() {
      if ( !isNaN(this.refAmount) ) {
        const currencyTo = toCurrencyCode(this.refCurrency)[0];
        if ( this.approxSign ) {
          return "~" + sigFigs(this.refAmount, 4) + " " + currencyFormat(currencyTo, false);
        } else {
          return sigFigs(this.refAmount, 4) + " " + currencyFormat(currencyTo, false);
        }
      } else {
        return "-";
      }
    },
  }
 }
</script>
