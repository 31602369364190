<template>
  <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="auctionModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header font-20">
          <h5 class="modal-title">Manual transfer details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body -fix-padding pb50">
          <div class="bg-color-w bg-mobile-fix-lr p_30">
             <div class="-small-padding font-15 text-color-dark">
               <div class="mb20">To transfer required amount to escrow request token smart contract at address:</div>
               <div class="bg-color -line p_20 mb40">
                 <div class="w">
                   <b>{{ currencyAddress }}</b>
                 </div>
               </div>

               <div class="mb20">With <b>"transfer"</b> method with fillowing parameters:</div>

               <div class="bg-color -line p_20 mb40">
                 <div class="w">
                   <p><b>_destination:</b> <span>{{ topupAddress }}</span></p>
                   <p><b>_amount</b> - <span>{{ amountCents }}</span></p>
                 </div>
               </div>

                <div class="mb20">
                  From <b>any account</b> that you control
                </div>

                <div class="mb20">
                  Token contract ABI file:
                </div>

                <div class="bg-color -line p_20 mb40">
                  <div class="w">
                    <p>{{ tokenAbiJson }}</p>
                  </div>
                </div>

             </div>
           </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmartContractManualTransferDetailsModal',
  props: {
    amount: {
      type: Number,
      required: true
    },
    amountCents: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    currencyAddress: {
      type: String,
      required: false,
      default: null
    },
    topupAddress: {
      type: String,
      required: true
    },
    blockchain: {
      type: String,
      required: true
    },
  },
  methods: {
    openModal: function() {
      $(this.$el).modal('show');
    }
  },
  computed: {
    tokenAbiJson: function() {
      return JSON.stringify(this.$parent.tokenABI);
    }
  }
}
</script>
