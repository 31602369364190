import Vue from 'vue/dist/vue.esm'
import DestinationAddress from '../components/destination_address'
import CountdownLight from '../components/countdown_light'

function waitForGlobal(key, callback) {
  if (window[key]) {
    callback();
  } else {
    setTimeout(() => {
      waitForGlobal(key, callback);
    }, 100);
  }
}

export function init() {
  const destinationsFormApp = new Vue({
    components: {
      DestinationAddress,
      CountdownLight,
    },
    data: {
      pubKey: null,
      destinationAddresses: {},
      errors: {
        destinationAddresses: {},
      },
      formReady: false,
    },
    methods: {
      isAllDestinationsSigned: function() {
        const vm = this;
        let allSigned = true;

        Object.keys(vm.$refs).forEach(el => {
          allSigned = allSigned && vm.$refs[el].isSignatureValid;
        })

        vm.formReady = allSigned;
        return allSigned;
      },
      destinationsToSign: function() {
        const vm = this;
        let destinations = []

        Object.keys(vm.$refs).forEach(el => {
          if ( !vm.$refs[el].isSignatureValid ) {
            destinations.push({ blockchain: vm.$refs[el].blockchain, address: vm.$refs[el].destination, messageHash: vm.$refs[el].messageToSign })
          }
        })

        return destinations;
      },
      askDistinationsSign: function() {
        const destinations = this.destinationsToSign();
        this.disableFormSubmits();
        try {
          window.whcypher.signDestinations(
            destinations,
            this.signSuccessful,
            this.signFailed
          );
        } catch (err) {
          this.signFailed(err);
        }
      },
      signSuccessful: function(signedDestinations) {
        const vm = this;
        const destinations = signedDestinations;

        Object.keys(vm.$refs).forEach(el => {
          let destination = destinations.filter((d) => d.messageHash === vm.$refs[el].messageToSign);
          if ( destination.length ) vm.$refs[el].setSignature(destination[0].signature);
        });

        setTimeout(function () {
          if ( vm.isAllDestinationsSigned() ) vm.$el.querySelector("form").submit();
        }, 1000);
      },
      signFailed: function(err) {
        $.pageErrorMessage("Error: " + err);
        this.enableFormSubmits();
      },
      validateAndSubmit: function(e) {
        if ( this.formReady ) {
          return true;
        } else {
          const form = e.currentTarget.form;
          if ( form.checkValidity() ) {
            if (e) e.preventDefault();
            this.askDistinationsSign();
          } else {
            form.reportValidity();
          }
        }
      },

      disableFormSubmits: function(disable = true) {
        $("form input[type=submit]").attr(
          "disabled",
          disable
        );
      },

      enableFormSubmits: function() {
        this.disableFormSubmits(false);
      },
    },
    mounted() {
      const vm = this;

      vm.isAllDestinationsSigned();

      waitForGlobal("signer", () => {
        vm.signerInstalled = !!window.whcypher;
      });
    }
  });
  return { destinationsFormApp }
}
