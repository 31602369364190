<template>
  <div class="form-group custom-form--currency-select g-mob-fix-width mt12 mb30">
    <div class="dropdown bootstrap-select show-tick form-control">
      <select
        required="required"
        title="Select vault"
        name="topup_request[ts_wallet_id]"
        id="topup_request_ts_wallet_id"
        class="selectpicker show-tick form-control"
        tabindex="-98"
        data-size=10
        v-model="selectedItemId"
      >
        <option
          v-for="item in items"
          :key="item.id"
          :value="item.id"
          :data-subtext="getBalance(item)"
          :title="item.address"
          :disabled="item.disabled"
          >{{ item.title }}</option
        >
      </select>
    </div>
    <div v-show="hasSelectedItem" class="wallet-amount desc-color font-bold">{{ selectedItemBalance }}</div>
  </div>
</template>

<script>
export default {
  name: "WalletSelector",
  props: {
    initialItems: Array,
  },
  data() {
    return {
      items: [],
      selectedItemId: null
    };
  },
  methods: {
    fetchBalances: function() {
      const vm = this;
      this.items.forEach((item) => {
        $.ajax({
          url: `/trading_multisigs/${item.id}/available_amounts`,
          type: "POST",
          tryCount: 0,
          retryLimit: 1,
          data: {
            id: item.id,
            currency: item.currency,
          },
          async: true,
          timeout: 20000, //we pending on other API here, so we need at least 20 seconds timeout
          success: function(response, textStatus) {
            const allItems = vm.items;
            const itemIndex = allItems.findIndex((i) => i.id === response.id);
            vm.$set(vm.items[itemIndex], "amount", response.amount);
          },
          error: function(XMLHttpRequest, textStatus, errorThrown) {
            if (textStatus == "timeout" || textStatus == "error") {
              this.tryCount++;
              if (this.tryCount <= this.retryLimit) {
                //try again
                $.ajax(this);
                return;
              }
              return;
            }
          },
        });
      });
    },
    getBalance: function(item) {
      return typeof item.amount === "undefined" || item.amount == null
        ? `Fetching…`
        : `${item.amount} ${item.currency_code}`;
    },
  },
  computed: {
    hasSelectedItem: function() {
      return !!this.selectedItem;
    },
    selectedItemBalance: function() {
      if ( this.hasSelectedItem ) {
        if ( this.selectedItem.amount ) {
          return `${this.selectedItem.amount} ${this.selectedItem.currency_code}`;
        } else {
          return `Fetching…`;
        }
      } else {
        return null;
      }
    },
    selectedItem: function() {
      const vm = this;
      const itemIndex = vm.items.findIndex((i) => i.id === vm.selectedItemId);
      return vm.items[itemIndex];
    }
  },
  watch: {
    items: {
      handler: function(newValue) {
        // request to redraw dropdown list bootstrap
        this.$nextTick(function() {
          $(".selectpicker").selectpicker("refresh");
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.items = this.initialItems;
    var vm = this;

    vm.$nextTick(function() {
      vm.fetchBalances();
    });
  },
};
</script>
