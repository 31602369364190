<template>
  <div>
    <ul v-if="hasErrors">
      <li class="error-input__text -active" v-for="error in errors">
        <div>{{ error }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ErrorsList',
  props: {
    errors: {
      type: Array,
      default: []
    }
  },
  computed: {
    hasErrors: function() {
      return this.errors.length > 0;
    }
  }
}
</script>
