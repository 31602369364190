<template>
  <a v-if="!addressVerified"
    role="button"
    class="font-12 text-color-light-green link-hover-color-dark -no-line ml-auto"
    @click="reSign"
  >Sign</a>
</template>

<script>
import { ec } from "elliptic";
import { sha256 } from "js-sha256";
import { eventBus } from "../utils/event_bus.js";

export default {
  name: "ResignAddressButton",
  props: {
    walletId: {
      type: String,
      required: true,
    },
    blockchain: {
      type: String,
      required: true,
    },
    publicKey: {
      type: String,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    },
    signature: {
      type: String,
      required: true,
    },
  },
  data: function() {
    return {
      currentSignature: String,
    };
  },
  methods: {
    reSign: function(url) {
      let destinations = [{ blockchain: this.blockchain, address: this.destination, messageHash: this.messageToSign }];
      try {
        window.whcypher.signDestinations(
          destinations,
          this.signatureSuccess.bind(this),
          this.signatureFailed.bind(this));
      } catch (err) {
        this.signatureFailed(err);
      }
    },
    signatureSuccess: function(signedDestinations) {
      if (signedDestinations.length > 0) {
        const response = signedDestinations[0];
        if (response.messageHash == this.messageToSign) {
          this.updateWalletSignature(response);
        } else {
          this.setErrorMessage("Signed message does not match. Something nasty is going on. Please contact support");
        }
      } else {
        this.setErrorMessage("Empty responce from wallet. Please contact support.");
      }
    },
    signatureFailed: function(err) {
      this.setErrorMessage(`Wallet returned an error: ${err}`);
    },
    setErrorMessage: function(errorMessage) {
      if ( this.$parent.setErrorMessage ) {
        this.$parent.setErrorMessage(errorMessage);
      } else {
        alert(errorMessage);
      }
    },
    updateWalletSignature: function(walletResponse) {
      const vm = this;
      $.ajax({
        url: `/trading_multisigs/${vm.walletId}`,
        type: "PUT",
        data: {
          ts_vault: {
            signature: walletResponse.signature,
          },
        },
        timeout: 10000,
        success: function(httpResponse, textStatus) {
          if (typeof httpResponse.ts_wallet_id !== "undefined") {
            eventBus.$emit(`wallet-siganture-changed-${vm.destination}`, walletResponse.signature);
          } else {
            const errMsgExists = typeof httpResponse.errors !== "undefined" && httpResponse.errors.length > 0;
            const errorMessage = errMsgExists ? httpResponse.errors[0].message : "Signature is invalid";
            this.setErrorMessage(errorMessage);
          }
        }.bind(this),
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          this.setErrorMessage("Failed to connect with WhalesHeaven. Error: " + errorThrown);
        },
      });
    },
  },
  computed: {
    messageToSign: function() {
      return sha256(this.blockchain + "(" + this.destination + ")");
    },
    addressVerified: function() {
      try {
        const curve = new ec("secp256k1");
        const pubKey_obj = curve.keyFromPublic(this.publicKey, "hex");
        const msg_obj = Buffer.from(this.messageToSign, "utf-8");
        return curve.verify(msg_obj, this.currentSignature, pubKey_obj);
      } catch (e) {
        return false;
      }
    }
  },
  created() {
    this.currentSignature = this.signature;
  },
};
</script>
