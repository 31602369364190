<template>
  <div class="row pt-0 pb-0 margins-card mt-20" :class="customClass">
    <div class="col-12 col-md-9 pay-card-l pt-4 pb-4 pl-4 pr-4">
      <div class="font-20 font-sm-18 mb-card">
        Payment {{ detailsSequenceNum }} - {{ title }}
        <div class="font-14 font-sm-12" v-if="hasHint">{{ hint }}</div>
      </div>

      <div class="row mb-card">
        <div class="col-12 font-14 font-bold">Amount</div>
        <div class="col-12 font-22 font-sm-17 font-bold">
          <span class="" v-if="!amountReady">
            <strong :class="{ 'fade-in' : !partiallyPaid && !fullAmountSent }" v-show="!partiallyPaid && !fullAmountSent">{{ sigFigs(amount, 4) }}</strong>
            <strong :class="{ 'fade-in' : partiallyPaid }" v-show="partiallyPaid"><s>{{ sigFigs(amount, 4) }}</s> {{ sigFigs(amountDue, 4) }}</strong>
            <strong :class="{ 'fade-in' : !partiallyPaid && fullAmountSent }" v-show="!partiallyPaid && fullAmountSent"><s>{{ sigFigs(amount, 4) }}</s></strong>
          </span>
          <span class="" v-else>
            <strong :class="{ 'fade-in' : amountReady }"><s>{{ sigFigs(amount, 4) }}</s></strong>
          </span>
          <strong v-html="currencyFormat(currency)"></strong>
        </div>
      </div>

      <div class="row mb-card">
        <div class="col-12 font-14 font-bold">Send {{ currencySymbol }} to</div>
        <div class="col-12 font-22 font-bold d-flex break-word">
          <div class="font-22 font-sm-17 d-inline-block mr-1">
            <div class="text-color-dark anywhere text-bold escrow-address ellipsis-one-lines">{{ topupAddress }}</div>
            <address-validation-status-shield
              :signature="topupAddressSignature"
              :blockchain="blockchain"
              :destination="topupAddress"
              :public-key="publicKey"
            ></address-validation-status-shield>
          </div>
        </div>
          <div class="col-12">
            <button class="qr-copy mr-3" @click="copyAddressToClipboard">
              <img :src="require('images/new/copy-icon.svg')" class="w-100 copy-blue"></img>
              <img :src="require('images/new/copy-icon-hover.svg')" class="w-100 hovered copy-black"></img>
              <img :src="require('images/new/checkmark-blue.svg')" class="w-100 checkmark-blue" style="display: none;"></img>
            </button>
            <button class="qr-copy mr-3" @click="showTopupAddressAsQRCode">
              <img :src="require('images/new/qr-icon.svg')" class="w-100"></img>
              <img :src="require('images/new/qr-icon-hover.svg')" class="w-100 hovered"></img>
            </button>
            <a :href="addressLinkInExplorer" target="_blank" class="qr-copy mr-3">
              <img :src="require('images/new/ext-link.svg')" class="w-100"></img>
              <img :src="require('images/new/ext-link-hover.svg')" class="w-100 hovered"></img>
            </a>
          </div>
      </div>

      <div class="d-flex">
        <div class=" font-15 font-sm-14 font-bold pt-1 pb-1 pb-md-0 mr-3" :class="{ 'text-color-footer': !quickPayButtonsEnabled }">Quick pay:</div>

        <div class=" pl-md-0" v-if="ofBlockchainProtocol('Ethereum')">

          <a class="quick-pay-btn mr-2 mr-sm-2 metamask-interact-button" :class="{ 'disabled': !quickPayButtonsEnabled }" @click="initWeb3Payment" v-if="web3Enabled && !isMobile">
            <img :src="require('images/new/pay-metamask.svg')" class="quick-pay-btn-inner"></img>
          </a>
          <a class="quick-pay-btn mr-2 mr-sm-2 myetherwallet-interact-button" :class="{ 'disabled': !quickPayButtonsEnabled }" href="https://www.myetherwallet.com/wallet/access" target="_blank">
            <img :src="require('images/new/pay-myetherewallet.svg')" class="quick-pay-btn-inner"></img>
          </a>
          <a class="quick-pay-btn mr-2 mr-sm-2 mycrypto-interact-button" :class="{ 'disabled': !quickPayButtonsEnabled }" href="https://app.mycrypto.com/dashboard#send-transaction" target="_blank">
            <img :src="require('images/new/pay-mycrypto.svg')" class="quick-pay-btn-inner"></img>
          </a>
          <a class="quick-pay-btn mr-2 mr-sm-2 trustwallet-interact-button" :class="{ 'disabled': !quickPayButtonsEnabled }" :href="trustWalletPaymentUrl" target="_blank" v-if="trustWalletAssetCoinId && isMobile">
            <img :src="require('images/new/pay-trust-wallet.svg')" class="quick-pay-btn-inner"></img>
          </a>
          <a class="quick-pay-btn mr-2 mr-sm-2 manual-transfere-button" :class="{ 'disabled': !quickPayButtonsEnabled }" @click="showPaymentDetails" v-if="!!currencyAddress">
            <img :src="require('images/new/pay-manual.svg')" class="quick-pay-btn-inner"></img>
            <smart-contract-manual-transfer-details-modal
              ref="paymentDetailsModal"
              :amount="amountDue"
              :amount-cents="amountDueCents"
              :currency="currency"
              :currency-address="currencyAddress"
              :topup-address="topupAddress"
              :blockchain="blockchain"
            ></smart-contract-manual-transfer-details-modal>
          </a>
        </div>

        <div class=" pl-md-0" v-if="ofBlockchainProtocol('Bitcoin')">
          <a class="quick-pay-btn mr-2 mr-sm-2 metamask-interact-button" :class="{ 'disabled': !quickPayButtonsEnabled }" :href="guardaPaymentUrl" target="_blank">
            <img :src="require('images/new/pay-guarda.svg')" class="quick-pay-btn-inner"></img>
          </a>
          <a class="quick-pay-btn mr-2 mr-sm-2 manual-transfere-button" :class="{ 'disabled': !quickPayButtonsEnabled }" @click="showPaymentDetails">
            <img :src="require('images/new/pay-manual.svg')" class="quick-pay-btn-inner"></img>
            <bitcoin-manual-transfer-details-modal
              ref="paymentDetailsModal"
              :amount="amountDue"
              :topup-address="topupAddress"
              :blockchain="blockchain"
            ></bitcoin-manual-transfer-details-modal>
          </a>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-3 bg-grey pay-card-r bg-light-grey">
      <div class="row h-100 align-items-center">
        <div class="col">
          <div class="row" v-if="!amountReady">
            <div class="col-12 text-center mb-2" :class="{ 'fade-in' : !amountReady }"><img :src="require('images/new/load-icon-big.svg')" class=" load-icon-big"></img></div>
            <div class="col-12 font-16 font-sm-15 text-center" v-if="awaitingConfirmation">Awaiting confirmation...</div>
            <div class="col-12 font-16 font-sm-15 text-center" v-else>Awaiting deposit...</div>
          </div>
          <div class="row" v-if="amountReady">
            <div class="col-12 text-center mb-2" :class="{ 'fade-in' : amountReady }"><img :src="require('images/new/success-icon.svg')"></img></div>
            <div class="col-12 font-16 font-sm-15 text-center">Deposit ready!</div>
          </div>
        </div>
      </div>
    </div>

    <qr-code-modal
      ref="qrCodeModal"
      :currency-symbol="currencySymbol"
      :topup-address="topupAddress"
    ></qr-code-modal>
  </div>
</template>

<script>

import { sigFigs, currencyFormat } from "utils/formatting";
import { copyToClipboard } from "utils/clipboard";
import { eventBus } from "../utils/event_bus.js";

import QrCodeModal from './qr_code_modal.vue'
import SmartContractManualTransferDetailsModal from './smart_contract_manual_transfer_details_modal.vue'
import BitcoinManualTransferDetailsModal from './bitcoin_manual_transfer_details_modal.vue'
import AddressValidationStatusShield from './address_validation_status_shield.vue'

export default {
  name: 'TopupRequestDetails',
  components: {
    QrCodeModal,
    SmartContractManualTransferDetailsModal,
    BitcoinManualTransferDetailsModal,
    AddressValidationStatusShield,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      required: false,
      default: null
    },
    dataLoaded: {
      type: Boolean,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    amountCents: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    currencyAddress: {
      type: String,
      required: false,
      default: null
    },
    topupAddress: {
      type: String,
      required: true
    },
    topupAddressSignature: {
      type: String,
      required: true
    },
    blockchain: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      required: false,
      default: ""
    },
  },
  data: function() {
    return {
      amountDue: null,
      amountDueCents: null,
      amountReady: false,
      detailsSequenceNum: 0,
      web3: null,
      addressVerified: false,
      ethereumAccountWallet: null,
    }
  },
  methods: {
    setDetailsSequenceNum: function(newDetailsSequenceNum) {
      this.detailsSequenceNum = newDetailsSequenceNum;
    },

    setAmountDue: function(newAmountDue, newAmountDueCents) {
      this.amountDue = newAmountDue;
      this.amountDueCents = newAmountDueCents;
    },

    setAmountReady: function(newAmountReady) {
      this.amountReady = newAmountReady;
      this.$parent.setTopupRequestReadines(this.detailsSequenceNum);
    },

    copyAddressToClipboard: function(e) {
      const $el = $(e.currentTarget);
      const $copyEl = $el.find(".copy-blue");
      const $copyElHover = $el.find(".copy-black");
      const $checkmarkEl = $el.find(".checkmark-blue");

      copyToClipboard(this.topupAddress);

      const delay = 300;
      $copyElHover.hide();
      $copyEl.fadeOut(delay, function() {
        $checkmarkEl.fadeIn(delay);

        setTimeout(function() {
          $checkmarkEl.fadeOut(delay, function() {
            $copyEl.fadeIn(delay);
            $copyElHover.css('display', '');
          });
        }, 1000);
      });
    },

    showTopupAddressAsQRCode: function() {
      this.$refs.qrCodeModal.openModal();
    },

    showPaymentDetails: function() {
      this.$refs.paymentDetailsModal.openModal();
    },

    web3Enabled: function() {
      return this.web3 != null;
    },

    initWeb3Payment: function(e) {
      e.preventDefault();

      const vm = this;
      const el = $(this.$el);

      vm.web3.eth.getAccounts(function(err, accounts) {
        if (err != null) {
          vm.pageErrorMessage(err);
        } else if (accounts.length === 0) {
          window.ethereum.enable().then(function() {
            vm.web3.eth.getAccounts(function(err, accounts) {
              vm.ethereumAccountWallet = accounts[0];
              vm.trackAccountChange();
              vm.checkBlockchainAndRequestPayment();
            });
          });
        } else {
          vm.ethereumAccountWallet = accounts[0];
          vm.trackAccountChange();
          vm.checkBlockchainAndRequestPayment();
        }
      });

    },

    trackAccountChange: function() {
      const vm = this;

      window.ethereum.on("accountsChanged", function(accounts) {
        if (accounts[0] !== vm.ethereumAccountWallet) {
          vm.ethereumAccountWallet = accounts[0];
        }
      });
    },

    checkBlockchainAndRequestPayment: function() {
      const vm = this;
      vm.web3.eth.getChainId().then(function(id) {
        try {
          if (id != vm.blockchainInfo["id"]) {
              vm.web3.currentProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: vm.blockchainInfo["id"] }]
              }).then(function(res, err) {
                vm.requestPayment();
              }).catch(function(error) {
                vm.web3.currentProvider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: vm.blockchainInfo["id"],
                      chainName: vm.blockchainInfo["name"],
                      rpcUrls: [vm.blockchainInfo["rpc"]],
                      nativeCurrency: {
                        symbol: vm.currencySymbol,
                        decimals: 18
                      },
                      blockExplorerUrls: [vm.blockchainInfo["explorer"]],
                    },
                  ],
                }).then(function(res, err) {
                  vm.requestPayment();
                }).catch(function(error) {
                  vm.pageErrorMessage("Something went wrong: " + errorMessage);
                });
              });
          } else {
            vm.requestPayment();
          };
        } catch(e) {
          vm.pageErrorMessage("Something went wrong: " + e);
        }
      });
    },

    requestPayment: function() {
      if(this.currencyAddress) {
        this.requestERC20Payment();
      } else if(this.topupAddress) {
        this.requestETHPayment();
      }
    },

    requestETHPayment: function() {
      const vm = this;
      var errorMessage = "";

      vm.web3.eth.getGasPrice(function(error, gasPrice) {
        if (error) {
          if (typeof error === "string" || error instanceof String)
            errorMessage = error;
          else errorMessage = error.message;
          vm.pageErrorMessage("Something went wrong: " + errorMessage);
        } else {
          vm.web3.eth.estimateGas(
            {
              from: vm.ethereumAccountWallet,
              to: vm.topupAddress,
              value: vm.amountDueCents,
            },
            function(error, gasAmount) {
              if (error != undefined) {
                if (typeof error === "string" || error instanceof String)
                  errorMessage = error;
                else errorMessage = error.message;
                vm.pageErrorMessage("Something went wrong: " + errorMessage);
              } else {
                vm.web3.eth.sendTransaction(
                  {
                    from: vm.ethereumAccountWallet,
                    to: vm.topupAddress,
                    value: vm.amountDueCents,
                    gasPrice: gasPrice,
                    gas: gasAmount,
                  },
                  function(error) {
                    if (error != undefined) {
                      if (
                        typeof error === "string" ||
                        error instanceof String
                      )
                        errorMessage = error;
                      else errorMessage = error.message;
                      vm.pageErrorMessage(
                        "Something went wrong: " + errorMessage
                      );
                    } else {
                      vm.pageSuccessMessage(
                        "Your transaction has been sent to blockchain. Once it is successfull, this page will be reloaded."
                      );
                    }
                  }
                );
              }
            }
          );
        }
      });
    },

    requestERC20Payment: function() {
      const vm = this;

      var tokenContract = new vm.web3.eth.Contract(
        vm.tokenABI,
        vm.currencyAddress
      );
      var data = tokenContract.methods
        .transfer(vm.topupAddress, vm.web3.utils.toBN(vm.amountDueCents))
        .encodeABI();
      var errorMessage = "";

      vm.web3.eth.getGasPrice(function(error, gasPrice) {
        if (error) {
          if (typeof error === "string" || error instanceof String)
            errorMessage = error;
          else errorMessage = error.message;
          vm.pageErrorMessage("Something went wrong: " + errorMessage);
        } else {
          vm.web3.eth.estimateGas(
            {
              from: vm.ethereumAccountWallet,
              to: vm.currencyAddress,
              data: data,
              value: 0,
            },
            function(error, gasAmount) {
              if (error != undefined) {
                if (typeof error === "string" || error instanceof String)
                  errorMessage = error;
                else errorMessage = error.message;

                if ( errorMessage.includes("subtraction overflow") ) {
                    vm.pageErrorMessage("Seems like there is not enough funds on your currently active wallet. Try another one.");
                } else {
                    vm.pageErrorMessage("Something went wrong: " + errorMessage);
                }
              } else {
                vm.web3.eth.sendTransaction(
                  {
                    from: vm.ethereumAccountWallet,
                    to: vm.currencyAddress,
                    data: data,
                    value: 0,
                    gasPrice: gasPrice,
                    gas: gasAmount,
                  },
                  function(error) {
                    if (error != undefined) {
                      if (
                        typeof error === "string" ||
                        error instanceof String
                      )
                        errorMessage = error;
                      else errorMessage = error.message;
                      vm.pageErrorMessage(
                        "Something went wrong: " + errorMessage
                      );
                    } else {
                      vm.pageSuccessMessage(
                        "Your transaction has been sent to blockchain. Once it is successfull, this page will be reloaded."
                      );
                    }
                  }
                );
              }
            }
          );
        }
      });
    },

    pageErrorMessage: function(message) {
      this.$root.$refs.statusModal.setHeader("An error occured...");
      this.$root.$refs.statusModal.setMessage(message);
      this.$root.$refs.statusModal.setSuccessStatus(false);
      this.$root.$refs.statusModal.openModal();
    },

    pageSuccessMessage: function(message) {
      this.$root.$refs.statusModal.setHeader("Success");
      this.$root.$refs.statusModal.setMessage(message);
      this.$root.$refs.statusModal.setSuccessStatus(true);
      this.$root.$refs.statusModal.openModal();
    },

    ofBlockchainProtocol: function(blockchainProtocol) {
      if ( blockchainProtocol == "Bitcoin" ) {
        return this.ofBitcoinBlockchainProtocol(this.blockchain);
      } else if ( blockchainProtocol == "Ethereum" ) {
        return this.ofEthereumBlockchainProtocol(this.blockchain);
      } else {
        return  false;
      }
    },

    ofBitcoinBlockchainProtocol: function(blockchain) {
      return ["Bitcoin", "BitcoinTestnet", "Litecoin", "Dogecoin", "BitcoinCash"].includes(blockchain);
    },

    ofEthereumBlockchainProtocol: function(blockchain) {
      return ["Ethereum", "EthereumRopsten", "EthereumGoerli", "BinanceSmartChain", "Polygon", "Avalanche"].includes(blockchain);
    },

    ...{ sigFigs, currencyFormat }
  },
  computed: {
    quickPayButtonsEnabled: function() {
      return this.dataLoaded && !this.fullAmountSent;
    },

    hasHint: function() {
      return typeof this.hint == "string" && this.hint != "";
    },

    partiallyPaid: function() {
      return this.amountDueCents < this.amountCents && this.amountDueCents > 0 && this.sigFigs(this.amountDue, 4) != this.sigFigs(this.amount, 4);
    },

    fullAmountSent: function() {
      return this.amountDueCents <= 0;
    },

    awaitingConfirmation: function() {
      return this.amountDueCents <= 0 && !this.amountReady;
    },

    isMobile: function() {
      return isMobile();
    },

    currencySymbol: function () {
      return this.currencyFormat(this.currency, false);
    },

    publicKey: function() {
      return this.$root.accountPubKey;
    },

    blockchainInfo: function() {
      switch (this.blockchain) {
        case "BinanceSmartChain":
          return {id: "0x38", name: "Binance Smart Chain Mainnet", rpc: "https://bsc-dataseed.binance.org", explorer: "https://bscscan.com"}
        case "Polygon":
          return {id: "0x89", name: "Polygon Mainnet", rpc: "https://polygon-rpc.com/", explorer: "https://polygonscan.com"}
        case "Avalanche":
          return {id: "0xa86a", name: "Avalanche C-Chain Mainnet", rpc: "https://api.avax.network/ext/bc/C/rpc", explorer: "https://snowtrace.io"}
        case "EthereumRopsten":
          return {id: "0x3"}
        case "EthereumGoerli":
        return {id: "0x5"}
        case "Ethereum":
          return {id: "0x1"}
        default:
          throw `unkown blockchain - ${this.blockchain}`
      }
    },

    addressLinkInExplorer: function() {
      let explorerLink;

      if (this.blockchain == "Bitcoin") {
        explorerLink = "https://live.blockcypher.com/btc/address/";
      } else if (this.blockchain == "BitcoinTestnet") {
        explorerLink = "https://live.blockcypher.com/btc-testnet/address/";
      } else if (this.blockchain == "Litecoin") {
        explorerLink = "https://live.blockcypher.com/ltc/address/";
      } else if (this.blockchain == "Dogecoin") {
        explorerLink = "https://live.blockcypher.com/doge/address/";
      } else if (this.blockchain == "BitcoinCash") {
        explorerLink = "https://blockchair.com/bitcoin-cash/";
      } else if (this.blockchain == "Ethereum") {
        explorerLink = "https://etherscan.io/address/";
      } else if (this.blockchain == "EthereumRopsten") {
        explorerLink = "https://ropsten.etherscan.io/address/";
      } else if (this.blockchain == "EthereumGoerli") {
        explorerLink = "https://goerli.etherscan.io/address/";
      } else if (this.blockchain == "BinanceSmartChain") {
        explorerLink = "https://bscscan.com/address/";
      } else if (this.blockchain == "Polygon") {
        explorerLink = "https://polygonscan.com/address/";
      } else if (this.blockchain == "Avalanche") {
        explorerLink = "https://snowtrace.io/address/";
      }

      explorerLink = explorerLink + this.topupAddress;

      return explorerLink;
    },

    trustWalletAssetCoinId: function() {
      switch(this.blockchain) {
        case 'Ethereum':
          return 60;
          break;
        case 'EthereumRopsten':
          return 60;
          break;
        case 'EthereumGoerli':
          return 60;
          break;
        case 'BinanceSmartChain':
          return 714;
          break;
        case 'Polygon':
          return 966;
          break;
        case 'Avalanche':
          return 10009000;
          break;
        default:
          return null;
      }
    },

    guardaPaymentUrl: function() {
      const paymentUrl = new URL("https://guarda.co/app/send");

      // def self.guarda_currency_code_normalized(currency_code)
      //   case currency_code
      //   when "BTC"
      //     "btc"
      //   when "LTC"
      //     "ltc"
      //   when "BTC:TESTNET"
      //     "btc-testnet"
      //   when "ETH"
      //     "eth"
      //   when "ETH:ROPSTEN"
      //     "ropsten"
      //   end
      // end
      //
      // paymentUrl.searchParams.append("amount", this.amount);
      // paymentUrl.searchParams.append("currencyTo", Guarda.guarda_currency_code_normalized(transaction.currency));
      // paymentUrl.searchParams.append("family", Guarda.guarda_currency_code_normalized(transaction.fee_currency));
      // paymentUrl.searchParams.append("addressTo", this.topupAddress);

      return paymentUrl.href;
    },

    trustWalletPaymentUrl: function() {
      const paymentUrl = new URL("https://link.trustwallet.com/send");

      paymentUrl.searchParams.append("coin", this.trustWalletAssetCoinId);

      if ( !!this.currencyAddress ) paymentUrl.searchParams.append("token_id", this.currencyAddress);

      paymentUrl.searchParams.append("address", this.topupAddress);
      paymentUrl.searchParams.append("amount", this.amount);

      return paymentUrl.href;
    },

    tokenABI: function() {
      return [
        {
          constant: true,
          inputs: [
            {
              name: "_owner",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              name: "balance",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            {
              name: "_to",
              type: "address",
            },
            {
              name: "_value",
              type: "uint256",
            },
          ],
          name: "transfer",
          outputs: [
            {
              name: "",
              type: "bool",
            },
          ],
          type: "function",
        },
      ];
    },
  },
  created() {
    if ( this.amountDue == null || this.amountDueCents == null ) {
      this.amountDue = this.amount;
      this.amountDueCents = this.amountCents;
    }
  },
  mounted() {
    const vm = this;

    vm.$nextTick(() => {
      vm.web3 = vm.$parent.web3;
    });
  }
}
</script>

<style scoped>
.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
