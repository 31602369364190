<template>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">>
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header font-20">
            <h5 class="modal-title">We are sorry to hear that!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="feedback-form">
              <div class="form-group"  :class="[(hasErrors('feedback') ? 'error-input -red' : '')]">
                <label>Tell us what can we improve to make you happier next time (min. 5 characters)</label>
                <textarea
                  class="form-control"
                  rows="5"
                  name="deal_rating[feedback]"
                  id="deal_rating_feedback"
                  required="required"
                  v-model="feedback"
                ></textarea>
                <span style="float: right;" :class="{ 'text-color-red': (feedback.length > 600) }">{{ feedback.length }} / 600</span>
                <errors-list :errors="errors.feedback"></errors-list>
              </div>
            </div>
            <div class="thankyou-message" style="display: none;">
              <div class="bg-color-w bg-mobile-fix-lr p_30 p-sm-20 mb20 text-center">
                <img :src="require('images/new/success-icon.svg')" class="image"></img>
              </div>
              <div class="-no-fix-width -small-padding font-15 text-color-dark">
                Thank you for your feedback. We will review it and will work on to improve your expirience in the future.
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" v-show="!feedbackGiven" :disabled="!enableSubmit" @click="submitForm" class="btn btn-primary" :class="{disabled: !enableSubmit}">Submit feedback</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { eventBus } from "../utils/event_bus.js";

import ErrorsList from "./errors_list";

export default {
  name: 'FeedbackFormModal',
  components: {
    ErrorsList,
  },
  props: {
    dealTransactionFuzzyId: {
      type: String,
      require: true,
    },
    rating: {
      type: Number,
      required: false,
    },
  },
  data: function () {
    return {
      feedback: "",
      errors: {
        feedback: []
      },
      feedbackGiven: false,
    };
  },
  methods: {
    submitForm: function (e) {
      e.preventDefault();
      const vm = this;

      let form = $(this.$el).find("form");

      $.ajax({
        type: "POST",
        url: `/deal_transactions/${this.dealTransactionFuzzyId}/give_feedback`,
        data: form.serialize(),
        dataType: "JSON",
        async: false,
        success: function(response, textStatus) {
          if ( response.status === 'OK' ) {
            vm.feedbackGiven = true;
            $(vm.$el).find('.feedback-form').fadeOut('slow', function() {
              $(vm.$el).find('.thankyou-message').fadeIn();
            });
            eventBus.$emit(`feedbackGiven`);
          }
        },
        error: function(xhr, response) {
          if ( xhr.responseJSON.errors ) {
            vm.errors.feedback = xhr.responseJSON.errors.feedback;
          } else {
            // something else
          }
        },
      }, 0);
    },

    openModal: function() {
      const vm = this;

      $(vm.$el).modal('show');
    },

    hasErrors: function(key) {
      return this.errors[key].length > 0;
    },
  },
  computed: {
    enableSubmit: function() {
      return this.feedback.length >= 0;
    },
  },
  mounted() {
    $(this.$el).on('hidden.bs.modal', function () {
      eventBus.$emit(`feedbackSkipped`);
    });
  }
}
</script>
