<template>
  <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="auctionModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header font-20">
          <h5 class="modal-title">Manual transfer details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body -fix-padding pb50">
          <div class="bg-color-w bg-mobile-fix-lr p_30 p-sm-20 mb20">
            <div class="-no-fix-width -small-padding font-15 text-color-dark">
              <div class="mb20">To transfer required amount to escrow multisig address:</div>
              <div class="bg-color -line p_20 mb20">
                <div class="w">
                  $ bitcoin-cli sendtoaddress "{{ topupAddress }}" {{ amount }}
                </div>
              </div>

              <div class="mb20">Please make sure that you provided correct fee for this transaction.</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BitcoinManualTransferDetailsModal',
  props: {
    amount: {
      type: Number,
      required: true
    },
    topupAddress: {
      type: String,
      required: true
    },
    blockchain: {
      type: String,
      required: true
    },
  },
  methods: {
    openModal: function() {
      $(this.$el).modal('show');
    }
  },
}
</script>
