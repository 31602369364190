import Vue from "vue/dist/vue.esm";
const Web3 = require("web3/dist/web3.min");

import CountdownLight from "../components/countdown_light";
import VerifySignature from "../components/verify_signature";
import WalletSelector from "../components/wallet_selector";
import TopupRequestDetails from '../components/topup_request_details';
import TransactionsTable from '../components/transactions_table';
import TopupRequestDetailsStatusModal from '../components/topup_request_details_status_modal.vue'

import { sigFigsString } from '../utils/formatting'

export function init() {
  const topupRequestsFormApp = new Vue({
    components: {
      CountdownLight,
      VerifySignature,
      WalletSelector,
      TopupRequestDetails,
      TopupRequestDetailsStatusModal,
      TransactionsTable,
    },
    data: {
      accountPubKey: null,
      blockchain: null,
      tsWalletId: null,
      web3: null,
      formSubmitted: false,
      topupRequestFuzzyId: null,
      topupRequestTsWalletFuzzyId: null,
      auctionFuzzyId: null,
      tsWalletCheckingBalance: null,
      topupRequestReadines: {},
      allPaymentsReady: false,
      topupRequestDataLoaded: false,
    },
    methods: {
      askGenNewVault: function() {
        const vm = this;
        vm.disableAllForms();

        $.ajax({
          url: "/trading_multisigs.json",
          type: "POST",
          data: {
            ts_vault: {
              blockchain: vm.blockchain,
            },
          },
          timeout: 10000,
          success: function(response, textStatus) {
            if ( !response.errors ) {
              vm.tsWalletId = response.ts_wallet_id;

              try {
                // Handle case when wallet is dissabled in the browser
                window.whcypher.joinVaultSession(
                  {
                    sessionId: response.session_id,
                    pubKey: vm.accountPubKey,
                    blockchain: vm.blockchain,
                  },
                  vm.generateApproved,
                  vm.generateRejected
                );
              } catch (err) {
                vm.generateRejected(err);
              }
            } else {
              vm.generateRejected(response.errors[0].message);
            }
          },
          error: function(XMLHttpRequest, textStatus, errorThrown) {
            vm.enableAllForms();
            alert("Error: " + errorThrown);
          },
        });
      },

      generateApproved: function(context) {
        const vm = this;
        vm.showRedirectModal();

        setInterval(function() {
          $.ajax({
            url: `/trading_multisigs/${vm.tsWalletId}.json`,
            type: "GET",
            timeout: 4000,
            success: function(response, textStatus) {
              if (response.state == "address_ready") {
                $.ajax({
                  url: `/trading_multisigs/${vm.tsWalletId}.json`,
                  type: "PUT",
                  data: {
                    ts_vault: {
                      blockchain: context.blockchain,
                      message: context.message,
                      signature: context.signature,
                    },
                  },
                  timeout: 10000,
                  success: function(response, textStatus) {
                    if (!response.error) {
                      const form = vm.$el.querySelector(
                        "form#generate-new-ts-wallet"
                      );
                      const idField = form.querySelector(
                        "#topup_request_ts_wallet_id"
                      );
                      idField.value = vm.tsWalletId;
                      if (!vm.formSubmitted) {
                        vm.formSubmitted = true;
                        form.submit();
                      }
                    } else {
                      vm.enableAllForms();
                      alert("Invalid signature");
                    }
                  },
                  error: function(XMLHttpRequest, textStatus, errorThrown) {
                    vm.enableAllForms();
                    alert("Error: " + errorThrown);
                  },
                });
              }
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
              vm.enableAllForms();
              alert("Error: " + errorThrown);
            },
          });
        }, 2000);
      },

      generateRejected: function(err) {
        if (err) {
          this.showErrorModal();
        } else {
          this.showErrorModal("Trading wallet generation was rejected");
        }
        this.enableAllForms();
      },

      showErrorModal: function(error = null) {
        this.$refs.statusModal.setHeader("Trading wallet generation failed");
        if (!error) {
          this.$refs.statusModal.setMessage("Unable to generate new trading wallet. Please try again or contact support.");
        } else {
          this.$refs.statusModal.setMessage(error);
        }
        this.$refs.statusModal.setSuccessStatus(false);
        this.$refs.statusModal.openModal();
      },

      showRedirectModal: function() {
        this.$refs.statusModal.setHeader("Redirecting");
        this.$refs.statusModal.setMessage("Trading wallet generated successfully. You will be redirected shortly.");
        this.$refs.statusModal.setSuccessStatus(true);
        this.$refs.statusModal.openModal();
      },

      disableAllForms: function(disable = true) {
        $("form#generate-new-ts-wallet input[type=submit]").attr(
          "disabled",
          disable
        );
        $("form#edit_topup_request  input[type=submit]").attr(
          "disabled",
          disable
        );
      },

      setTopupRequestReadines: function(n) {
        this.topupRequestReadines[n] = true;

        const allElemsReady = Object.values(this.topupRequestReadines).every(v => v === true);

        if ( allElemsReady ) {
          this.allPaymentsReady = true;
        }
      },

      notifyPaymentsReady: function() {
        const vm = this;

        setTimeout(function() {
          $.ajax({
            url: `/topup_requests/${vm.topupRequestFuzzyId}/ready`,
            type: "POST",
            timeout: 4000,
            success: function(response, textStatus) {
              if (response.status == "OK") {
                // everything is fine o_0
              }
            }
          });
        }, 10000);
      },

      enableAllForms: function() {
        this.disableAllForms(false);
      },

      validateFormAndGenerateMultisig: function(e) {
        e.preventDefault();
        this.askGenNewVault();
      },

      validateFormAndContinue: function(e) {
        e.preventDefault();
        let form = $("form#edit_topup_request");
        if (form[0].checkValidity()) {
          this.disableAllForms()
          form.trigger("submit")
        }
      },

      checkAmountDue: function(topup_request_id, auction_id) {
        const vm = this;

        $.ajax({
          url: `/topup_requests/${topup_request_id}/state.json`,
          type: "GET",
          timeout: 10000,
          success: function(response, textStatus) {
            vm.topupRequestDataLoaded = true;

            var redirectToAuction = response.funds_locked == true;
            if (redirectToAuction) {
              if (response.deal_id) {
                window.location.href = "/trades/" + response.deal_id;
              } else {
                window.location.href = "/sell_offers/" + auction_id;
              }
            } else {
              vm.$refs.mainDeposit.setAmountDue(response.amount_due_coins, response.amount_due_cents);
              vm.$refs.mainDeposit.setAmountReady(response.amount_ready);

              if (response.currency_is_token) {
                vm.$refs.feeDeposit.setAmountDue(response.fee_amount_due_coins, response.fee_amount_due_cents);
                vm.$refs.feeDeposit.setAmountReady(response.fee_amount_ready);
              }

              vm.$refs.transactionsTable.setTransactions(response.transactions);
              vm.$refs.transactionsTable.setMinConfirmations(response.min_confirmations);
            }
          },
        });
      },

    },
    computed: {
      web3Enabled: function() {
        return this.web3 != null;
      },
    },
    watch: {
      allPaymentsReady: function(newValue, oldValue) {
        if ( newValue && !oldValue) {
          this.notifyPaymentsReady();
        }
      },
    },
    mounted() {
      const vm = this;

      let n = 1;
      for(let i = 0; i < vm.$children.length; i++) {
        if ( vm.$children[i].$options.name == 'TopupRequestDetails' ) {
          vm.$children[i].setDetailsSequenceNum(n);
          vm.topupRequestReadines[n] = false;
          n++;
        }
      }

      if (Web3.givenProvider != null) {
        vm.web3 = new Web3(Web3.givenProvider);
      }

      if (vm.topupRequestFuzzyId && vm.topupRequestTsWalletFuzzyId) {
        vm.checkAmountDue(vm.topupRequestFuzzyId, vm.auctionFuzzyId);

        if (vm.tsWalletCheckingBalance) {
          setInterval(function() {
            vm.checkAmountDue(vm.topupRequestFuzzyId, vm.auctionFuzzyId);
          }, 10000);
        }
      }
    },
  });
  return { topupRequestsFormApp };
}
