<template>
  <div class="times text-color-footer text-center text-uppercase auction-countdown" :class="{ '-three' : skipDays }"></div>
</template>

<script>

export default {
  name: 'Countdown',
  props: {
    finishesAt: {
      type: String,
      require: true,
    },
    freeze: {
      type: Boolean,
      default: false,
    },
    skipDays: {
      type: Boolean,
      default: false,
    },
    redirectTo: {
      type: String,
      require: true,
    }
  },
  mounted() {
    const vm = this;

    var countdown = $(vm.$el).countdown(vm.finishesAt, function(event) {
      if (event.elapsed) {
        window.location.href = vm.redirectTo;
      }

      if (vm.skipDays) {
        var timerHTML = '<div class="c"><div class="top font-20 font-sm-15">%H</div><div class="bottom font-12 font-sm-9">Hours</div></div>' +
          '<div class="c"><div class="top font-20 font-sm-15">%M</div><div class="bottom font-12 font-sm-9">Min</div></div>' +
          '<div class="c"><div class="top font-20 font-sm-15 text-color-blue">%S</div><div class="bottom font-12 font-sm-9">Sec</div></div>';
      } else {
        var timerHTML = '<div class="c"><div class="top font-20 font-sm-15">%D</div><div class="bottom font-12 font-sm-9">Days</div></div>' +
          '<div class="c"><div class="top font-20 font-sm-15">%H</div><div class="bottom font-12 font-sm-9">Hours</div></div>' +
          '<div class="c"><div class="top font-20 font-sm-15">%M</div><div class="bottom font-12 font-sm-9">Min</div></div>' +
          '<div class="c"><div class="top font-20 font-sm-15 text-color-blue">%S</div><div class="bottom font-12 font-sm-9">Sec</div></div>';
      }

      $(vm.$el).html(event.strftime(timerHTML));
    });

    if (vm.freeze) $(vm.$el).countdown("stop");
  }
}
</script>
