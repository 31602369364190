import Vue from 'vue/dist/vue.esm'
import SearchResults from '../components/search_results'
import LoginButton from '../components/login_button'
import CreateAuctionRedirectButton from '../components/create_auction_redirect_button'

export function init() {
  const searchPageApp = new Vue({
    components: {
      SearchResults,
      LoginButton,
      CreateAuctionRedirectButton,
    },
    data: {
      searchFormInitialized: false,
      searchFormUpdated: false,
      loadingResults: false,
      noMoreResults: false,
      zeroResults: false,
      searchFormAuctionLotCurrency: [],
      searchFormAuctionLotAcceptedCurrencies: [],
      searchFormAuctionLotOfferTypes: [],
      searchFormSorting: '',
    },
    methods: {
      searchFormReady: function() {
        this.searchFormInitialized = true;
        return this.searchFormInitialized;
      },
      setLoadingResults: function(value) {
        this.loadingResults = value;
        return this.loadingResults;
      },
      setZeroResults: function(value) {
        this.zeroResults = value;
        return this.zeroResults;
      },
      setNoMoreResults: function(value) {
        this.noMoreResults = !!value;
        return this.noMoreResults;
      },
      getTopPos: function(el) {
        var rect = el.getBoundingClientRect(),
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return rect.top + scrollTop;
      }
    },
    watch:{
      searchFormAuctionLotCurrency: function () {
        if (this.searchFormInitialized) {
          this.searchFormUpdated = true;
        }
      },
      searchFormAuctionLotAcceptedCurrencies: function () {
        if (this.searchFormInitialized) {
          this.searchFormUpdated = true;
        }
      },
      searchFormAuctionLotOfferTypes: function () {
        if (this.searchFormInitialized) {
          this.searchFormUpdated = true;
        }
      },
      searchFormSorting: function () {
        if (this.searchFormInitialized) {
          this.searchFormUpdated = true;
        }
      }
    },
    mounted() {
      var vm = this;
      const table = vm.$root.$el.querySelector('.results');
      // Each time the user scrolls
      window.addEventListener('scroll', function() {
        if ( !vm.loadingResults && !vm.noMoreResults ) {
          var windowBottomPosition = window.scrollY + window.innerHeight;
          var windowBottomMargin = 200; // in pixels
          var resultsBottom = vm.getTopPos(table) + table.offsetHeight;
          if (windowBottomPosition + windowBottomMargin > resultsBottom) {
            if ( vm.$refs.results ) vm.$refs.results.loadMore();
          }
        }
      });

      intiToggleBlock();
    }
  });
  return { searchPageApp }
}
