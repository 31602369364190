import Vue from 'vue/dist/vue.esm'
import DestinationAddress from '../components/destination_address'
import ErrorsList from '../components/errors_list'

import { sigFigs } from '../utils/formatting'

export function init() {
  const withdrawalFormApp = new Vue({
    components: {
      DestinationAddress,
      ErrorsList,
    },
    data: {
      currency: null,
      withdrawalAmount: null,
      minimumAmounts: {},
      availableAmounts: {},
      pubKey: null,
      errors: {
        withdrawalAmount: [],
      },
      canUpdateErrors: false,
      formReady: false,
    },
    computed: {
      isCurrencySet: function() {
        return typeof this.currency === "string";
      },
      amountMinimumLimit: function() {
        return sigFigs(this.minimumAmounts[this.currency], 5);
      },
      amountMaximumLimit: function() {
        return sigFigs(this.availableAmounts[this.currency], 5);
      },
    },
    methods: {
      isAllDestinationsSigned: function() {
        this.formReady = this.$refs.destinationAddress.isSignatureValid;
        return this.formReady;
      },
      destinationsToSign: function() {
        const vm = this;
        let destinations = [];
        const destinationEl = vm.$refs.destinationAddress;

        if (!destinationEl.isSignatureValid) {
          destinations.push({
            blockchain: destinationEl.blockchain,
            address: destinationEl.destination,
            messageHash: destinationEl.messageToSign,
          });
        }

        return destinations;
      },
      askDistinationsSign: function() {
        const destinations = this.destinationsToSign();

        try {
          window.whcypher.signDestinations(
            destinations,
            this.signSuccessful,
            this.signFailed
          );
        } catch (err) {
          this.signFailed(err);
        }
      },
      signSuccessful: function(signedDestinations) {
        const vm = this;

        vm.$refs.destinationAddress.setSignature(signedDestinations[0].signature);

        setTimeout(function () {
          if ( vm.isAllDestinationsSigned() ) vm.$el.querySelector("form").submit();
        }, 1000);
      },
      signFailed: function(err) {
        $.pageErrorMessage("Error: " + err);
      },
      validateAndSubmit: function(e) {
        if (this.formReady) {
          return true;
        } else {
          const form = e.currentTarget.form;
          if (form.checkValidity()) {
            if (e) e.preventDefault();
            this.askDistinationsSign();
          } else {
            form.reportValidity();
          }
        }
      },
      setMinimum: function() {
        this.withdrawalAmount = this.amountMinimumLimit;
      },
      setMaximum: function() {
        this.withdrawalAmount = this.amountMaximumLimit;
      },
      hasErrors: function(key) {
        return this.errors[key].length > 0;
      },
    },
  });
  return { withdrawalFormApp };
}
