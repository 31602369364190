<template>
  <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="auctionModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header font-20">
          <h5 class="modal-title">Approve transaction in WH Cypher</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body -fix-padding">
          <div class="bg-color-w bg-mobile-fix-lr p_30 p-sm-20">
            <div class="-no-fix-width -small-padding font-15 text-color-dark">
              <div class="mb10"><b>Step 1:</b> Open <b>WH Cypher</b> in your browser</div>
              <div class="bg-color -line p_20 mb20">
                <div class="screenshot-wrapper">
                  <img :src="require('images/new/approve_step_1.png')" class="screenshot"></img>
                </div>
              </div>

              <div class="mb10"><b>Step 2:</b> Click <b>information sign</b> next to your Trading wallet</div>
              <div class="bg-color -line p_20 mb20">
                <div class="screenshot-wrapper">
                  <img :src="require('images/new/approve_step_2.png')" class="screenshot"></img>
                </div>
              </div>

              <div v-if="!additionalStep">
                <div class="mb10"><b>Step 3:</b> Click <b>Join</b> button</div>
                <div class="bg-color -line p_20">
                  <div class="screenshot-wrapper">
                    <img :src="require('images/new/approve_step_3.png')" class="screenshot"></img>
                  </div>
                </div>
              </div>

              <div v-if="additionalStep == 'cancelAutoApproval'">
                <div class="mb10"><b>Step 3:</b> If autoapproval stuck, don't worry. Click <b>Cancel</b> button</div>
                <div class="bg-color -line p_20">
                  <div class="screenshot-wrapper">
                    <img :src="require('images/new/approve_step_3a.png')" class="screenshot"></img>
                  </div>
                </div>

                <div class="mb10"><b>Step 4:</b> Click <b>Join</b> button</div>
                <div class="bg-color -line p_20">
                  <div class="screenshot-wrapper">
                    <img :src="require('images/new/approve_step_3.png')" class="screenshot"></img>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhcypherApproveGuidanceModal',
  data: function() {
    return {
      additionalStep: null,
    };
  },
  methods: {
    openModal: function(additionalStep) {
      this.additionalStep = additionalStep;
      $(this.$el).modal('show');
    },
    closeModal: function() {
      $(this.$el).modal('hide');
      // $(".modal-backdrop").hide();
    }
  },
}
</script>
