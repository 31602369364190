import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import Countdown from '../components/countdown'
import PriceHistory from '../components/price_history'
import LoginButton from '../components/login_button'
import BuyoutProceedButton from "../components/buyout_proceed_button";

import {
  sigFigs,
  currencyFormat,
  normalizeCurrency,
  nFormatter,
} from '../utils/formatting'

import { debounce } from 'lodash';

export function init() {
  const auctionSinglePageApp = new Vue({
    components: {
      Countdown,
      PriceHistory,
      LoginButton,
      BuyoutProceedButton,
    },
    data: {
      auctionId: null,
      auctionState: null,
      auctionAmount: null,
      auctionCurrency: null,
      refCurrency: null,
      auctionRunning: null,
      auctionMarginDiscount: null,
      buyerCurrency: null,
      buyerAmountEstimateCurrency: null,
      buyerAmountInEstimatedCurrency: null,
      auctionSellLimitSet: null,
      auctionSellLimitMin: null,
      auctionSellLimitMax: null,
      auctionSellLimitCurrency: null,
      accountLimitMinInBaseCurrency: null,
      accountLimitMaxInBaseCurrency: null,
      currencyRatesBase: null,
      currencyRates: {},
      ratesLoaded: false,
      disableStepValidation: false,
      buyerNetworkFee: NaN,
      buyerNetworkFeeLoading: true,
      buyerNetworkCurrency: null,
      sellerNetworkFee: NaN,
      sellerNetworkCurrency: null,
    },
    methods: {
      loadCurrencyRates: function() {
        const vm = this;

        axios.get("/api_v1/current_rates.json", {
          headers: {
            'Content-Type': "application/json; charset=utf-8"
          }
        })
        .then(function (response) {
          vm.currencyRatesBase = response.data.base;
          vm.currencyRates = response.data.rates;
          vm.ratesLoaded = true;
        })
        .catch(function (error) {
          console.log('Error! Could not reach the API. ' + error)
        })
      },

      loadEstimateFee: function() {
        const vm = this;

        this.buyerNetworkFeeLoading = true;

        $.ajax({
          url: `/buy_offers/estimate_fee.json?currencies=${this.buyerCurrency},${this.auctionCurrency}`,
          type: "GET",
          timeout: 10000,
          success: function(response, textStatus) {
            vm.buyerNetworkFeeLoading = false;

            if (response.error) {
              vm.buyerNetworkFee = NaN;
              vm.buyerNetworkCurrency = null;
            }

            if (!response[0].error) {
              vm.buyerNetworkFee = parseFloat(response[0].fee_amount);
              vm.buyerNetworkCurrency = response[0].fee_currency;
            } else {
              vm.buyerNetworkFee = NaN;
              vm.buyerNetworkCurrency = null;
            }

            if (!response[1].error) {
              vm.sellerNetworkFee = parseFloat(response[1].fee_amount);
              vm.sellerNetworkCurrency = response[1].fee_currency;
            } else {
              vm.sellerNetworkFee = NaN;
              vm.sellerNetworkCurrency = null;
            }
          },
          error: function(XMLHttpRequest, textStatus, errorThrown) {
            vm.buyerNetworkFeeLoading = false;
            console.log("Error: " + errorThrown);
          },
        });
      },

      initCheckAuctionStateRoutine: function() {
        const vm = this;
        var checkStatusEvery_seconds = 30;
        var tillNextCheckStatus_seconds = checkStatusEvery_seconds;

        setInterval(
          function checkState() {
            $.ajax({
              url: `/sell_offers/${vm.auctionId}/state.json`,
              type: "GET",
              timeout: 5000,
              success: function(response, textStatus) {
                tillNextCheckStatus_seconds = checkStatusEvery_seconds;

                if (response.state != vm.auctionState) {
                  location.reload();
                }
              }
            });
          },
          checkStatusEvery_seconds*1000
        )
      },

      convert: function(amountFrom, currencyFrom, currencyTo) {
        const currencyFromNormalized = this.normalizeCurrency(currencyFrom);
        const currencyToNormalized = this.normalizeCurrency(currencyTo);
        return amountFrom*this.currencyRates[currencyFromNormalized]/this.currencyRates[currencyToNormalized];
      },

      switchBuyerAmountCurrency: function() {
        if ( this.buyerAmountEstimateCurrency === this.refCurrency && this.isBuyerCurrencySet ) {
          this.buyerAmountEstimateCurrency = this.buyerCurrency;
        } else {
          this.buyerAmountEstimateCurrency = this.refCurrency;
        }
        return true;
      },

      validateAndSubmit: function(event) {
        const e = event;
        const vm = this;

        this.disableStepValidation = true;

        if ( vm.$el.querySelector("form").checkValidity() ) {
          return true;
        } else {
          setTimeout(function() {
            vm.disableStepValidation = false;
          }, 100);
          return true;
        }
      },

      calculateInputStep: function(min, max) {
        if ( max == 0 ) {
          return "any";
        } else if ( max < 1 ) {
          return this.sigFigs(((max-min)/10),4);
        } else {
          return 1;
        }
      },

      setBuyAmountMinimumInEstimatedCurrency: function() {
        this.buyerAmountInEstimatedCurrency = this.buyAmountMinimumInEstimatedCurrency;
      },

      setBuyAmountMaximumInEstimatedCurrency: function() {
        this.buyerAmountInEstimatedCurrency = this.buyAmountMaximumInEstimatedCurrency;
      },

      ...{ sigFigs, currencyFormat, normalizeCurrency, nFormatter }
    },
    computed: {

      baseCurrency: function() {
        return "USD";
      },

      isBuyerCurrencySet: function() {
        return typeof this.buyerCurrency === 'string';
      },

      buyerAmountEstimateCurrencyFormatted: function() {
        if ( this.buyerAmountEstimateCurrency !== null ){
          return toCurrencyCode(this.buyerAmountEstimateCurrency)[0];
        } else {
          return null;
        }
      },

      changeToBuyerAmountEstimateCurrency: function() {
        if ( this.buyerAmountEstimateCurrency !== null ){
          if ( this.buyerAmountEstimateCurrency === this.refCurrency ){
            return this.buyerCurrency;
          } else {
            return this.refCurrency;
          }
        } else {
          return null;
        }
      },

      changeToBuyerAmountEstimateCurrencyFormatted: function() {
        if ( this.changeToBuyerAmountEstimateCurrency !== null ){
          return toCurrencyCode(this.changeToBuyerAmountEstimateCurrency)[0];
        } else {
          return null;
        }
      },

      canShowMinBuyAmount: function() {
        return this.isBuyerCurrencySet && this.buyAmountMinimumInBaseCurrency != null && !isNaN(this.buyAmountMinimumInBaseCurrency);
      },

      canShowMaxBuyAmount: function() {
        return this.isBuyerCurrencySet && this.buyAmountMaximumInBaseCurrency != null && !isNaN(this.buyAmountMaximumInBaseCurrency);
      },

      buyAmountLimitStepInEstimatedCurrency: function() {
        if ( this.disableStepValidation ) {
          return "any";
        } else {
          return this.calculateInputStep(this.buyAmountMinimumInEstimatedCurrency, this.buyAmountMaximumInEstimatedCurrency);
        }
      },

      // HERE
      buyAmountMinimumInBaseCurrency: function() {
        if ( this.ratesLoaded && this.auctionSellLimitSet && this.auctionSellLimitMin != null && !isNaN(this.auctionSellLimitMin) ) {
          const currencyFrom = toCurrencyCode(this.auctionSellLimitCurrency)[0];
          const currencyTo = toCurrencyCode(this.baseCurrency)[0];
          const buyLimitMin = this.auctionSellLimitMin * (1+this.auctionMarginDiscount/100);
          if (!isNaN(this.buyerNetworkFeeFactorInEstimatedCurrency)) {
            const refCurrency = toCurrencyCode(this.refCurrency)[0];
            const buyerNetworkFeeFactorInBaseCurrency = this.convert(this.buyerNetworkFeeFactorInRefCurrency, refCurrency, currencyTo)
            const auctionSellLimitMinInBaseCurrency = this.convert(buyLimitMin, currencyFrom, currencyTo) + buyerNetworkFeeFactorInBaseCurrency;
            return Math.max(this.accountLimitMinInBaseCurrency, auctionSellLimitMinInBaseCurrency);
          } else {
            return this.accountLimitMinInBaseCurrency;
          }
        } else {
          return this.accountLimitMinInBaseCurrency;
        }
      },

      buyAmountMaximumInBaseCurrency: function() {
        if ( this.ratesLoaded && this.auctionSellLimitSet && this.auctionSellLimitMax != null && !isNaN(this.auctionSellLimitMax) ) {
          const currencyFrom = toCurrencyCode(this.auctionSellLimitCurrency)[0];
          const currencyTo = toCurrencyCode(this.baseCurrency)[0];
          const buyLimitMax = this.auctionSellLimitMax * (1+this.auctionMarginDiscount/100);
          const auctionSellLimitMaxInBaseCurrency = this.convert(buyLimitMax, currencyFrom, currencyTo);
          return Math.min(this.accountLimitMaxInBaseCurrency, auctionSellLimitMaxInBaseCurrency);
        } else {
          return this.accountLimitMaxsInBaseCurrency;
        }
      },

      buyAmountMinimumInEstimatedCurrency: function() {
        if ( this.canShowMinBuyAmount ) {
          const currencyFrom = toCurrencyCode(this.baseCurrency)[0];
          const currencyTo = toCurrencyCode(this.buyerAmountEstimateCurrency)[0];
          return this.sigFigs(this.convert(this.buyAmountMinimumInBaseCurrency, currencyFrom, currencyTo),4);
        } else {
          return 0;
        }
      },

      buyAmountMinimumInEstimatedCurrencyFormatted: function() {
        if ( this.canShowMinBuyAmount ) {
          return this.buyAmountMinimumInEstimatedCurrency + " " + this.buyerAmountEstimateCurrencyFormatted;
        } else {
          return "-";
        }
      },

      buyAmountMaximumInEstimatedCurrency: function() {
        if ( this.canShowMaxBuyAmount ) {
          const currencyFrom = toCurrencyCode(this.baseCurrency)[0];
          const currencyTo = toCurrencyCode(this.buyerAmountEstimateCurrency)[0];
          return this.sigFigs(this.convert(this.buyAmountMaximumInBaseCurrency, currencyFrom, currencyTo),4);
        } else {
          return 0;
        }
      },

      buyAmountMaximumInEstimatedCurrencyFormatted: function() {
        if ( this.canShowMaxBuyAmount ) {
          return this.buyAmountMaximumInEstimatedCurrency + " " + this.buyerAmountEstimateCurrencyFormatted;
        } else {
          return "-";
        }
      },

      canShowSellingAmount: function() {
        return this.buyerAmountInEstimatedCurrency != null && !isNaN(this.buyerAmountInEstimatedCurrency);
      },

      buyAmountInBaseCurrency: function() {
        const auctionBuyAmountSet = typeof this.auctionAmount == "number" && this.auctionCurrency && this.buyerCurrency;
        const buyerAmount = this.buyerAmountInEstimatedCurrency;

        if ( typeof buyerAmount == "number" && this.ratesLoaded && auctionBuyAmountSet ) {
          const currencyFrom = toCurrencyCode(this.buyerAmountEstimateCurrency)[0];
          const currencyTo = toCurrencyCode(this.baseCurrency)[0];
          return this.convert(buyerAmount, currencyFrom, currencyTo);
        } else {
          return NaN;
        }
      },

      buyAmountIsInLimits: function() {
        const auctionSellLimitMinSet = this.auctionSellLimitMin != null && !isNaN(this.auctionSellLimitMin);
        const auctionSellLimitMaxSet = this.auctionSellLimitMax != null && !isNaN(this.auctionSellLimitMax);

        const buyAmount = this.buyAmountInBaseCurrency;

        if ( !isNaN(buyAmount) && this.auctionSellLimitSet && auctionSellLimitMinSet && auctionSellLimitMaxSet ) {
          return buyAmount >= (this.buyAmountMinimumInBaseCurrency*0.995) && buyAmount <= (this.buyAmountMaximumInBaseCurrency*1.005);
        } else {
          return true; // hide errors
        }
      },

      buyAmountInBuyerCurrency: function() {
        if ( typeof this.auctionAmount == "number" && this.auctionCurrency && this.buyerCurrency && !isNaN(this.buyerNetworkFeeFactorInAuctionCurrency) ) {
          let buyAmountWithMargin;

          if ( this.auctionSellLimitSet ) {
            buyAmountWithMargin = this.auctionAmount * (1+this.auctionMarginDiscount/100);
          } else {
            buyAmountWithMargin = this.auctionAmount * (1+this.auctionMarginDiscount/100) + this.buyerNetworkFeeFactorInAuctionCurrency;
          }

          // Sometimes fees might be simply too high, so that the final amount is negative.
          // In this case we fall back no default values and will handle that separately
          if ( buyAmountWithMargin < 0 ) {
            buyAmountWithMargin = this.auctionAmount * (1+this.auctionMarginDiscount/100);
          }

          const currencyFrom = toCurrencyCode(this.auctionCurrency)[0];
          const currencyTo = toCurrencyCode(this.buyerCurrency)[0];
          return this.convert(buyAmountWithMargin, currencyFrom, currencyTo);
        } else {
          return NaN;
        }
      },

      buyAmountInBuyerCurrencyFormatted: function() {
        const buyAmount = this.buyAmountInBuyerCurrency;

        if ( typeof buyAmount == "number" && this.buyerCurrency ) {
          if ( buyAmount > 1000 ) {
            return this.nFormatter(buyAmount, 4) + " " + this.currencyFormat(this.buyerCurrency);
          } else {
            return this.sigFigs(buyAmount, 4) + " " + this.currencyFormat(this.buyerCurrency);
          }
        } else {
          return "-";
        }
      },

      buyAmountInRefCurrency: function() {
        const buyAmount = this.buyAmountInBuyerCurrency;

        if ( typeof buyAmount == "number" && this.auctionCurrency && this.refCurrency ) {
          const currencyFrom = toCurrencyCode(this.buyerCurrency)[0];
          const currencyTo = toCurrencyCode(this.refCurrency)[0];
          return this.convert(buyAmount, currencyFrom, currencyTo);
        } else {
          return NaN;
        }
      },

      buyAmountInRefCurrencyFormatted: function() {
        const buyAmount = this.buyAmountInRefCurrency;

        if ( !isNaN(buyAmount) ) {
          return "~" + this.sigFigs(buyAmount, 4) + " " + this.currencyFormat(this.refCurrency);
        } else {
          return "-";
        }
      },

      // HERE
      sellingAmountInSellerCurrency: function() {
        if ( this.canShowSellingAmount && !isNaN(this.buyerNetworkFeeFactorInEstimatedCurrency) ) {
          const sellingAmountWithMargin = this.buyerAmountInEstimatedCurrency / (1+this.auctionMarginDiscount/100) - this.buyerNetworkFeeFactorInEstimatedCurrency;
          const currencyFrom = toCurrencyCode(this.buyerAmountEstimateCurrency)[0];
          const currencyTo = toCurrencyCode(this.auctionCurrency)[0];
          return this.convert(sellingAmountWithMargin, currencyFrom, currencyTo);
        } else {
          return NaN;
        }
      },

      sellingAmountInSellerCurrencyFormatted: function() {
        const sellingAmount = this.sellingAmountInSellerCurrency;

        if ( typeof sellingAmount == "number" && this.auctionCurrency ) {
          return this.sigFigs(sellingAmount, 4) + " " + this.currencyFormat(this.auctionCurrency);
        } else {
          return "-";
        }
      },

      sellingAmountInRefCurrency: function() {
        const sellingAmount = this.sellingAmountInSellerCurrency;

        if ( typeof sellingAmount == "number" && this.auctionCurrency ) {
          const currencyFrom = toCurrencyCode(this.auctionCurrency)[0];
          const currencyTo = toCurrencyCode(this.refCurrency)[0];
          return this.convert(sellingAmount, currencyFrom, currencyTo);
        } else {
          return NaN;
        }
      },

      sellingAmountInRefCurrencyFormatted: function() {
        const sellingAmount = this.sellingAmountInRefCurrency;

        if ( !isNaN(sellingAmount) ) {
          return "~" + this.sigFigs(sellingAmount, 4) + " " + this.currencyFormat(this.refCurrency);
        } else {
          return "-";
        }
      },

      buyerNetworkFeeFormatted: function() {
        if ( this.buyerNetworkFeeLoading ) {
          return "Waiting..."
        } else {
          if ( !isNaN(this.buyerNetworkFee) ) {
            return "~" + this.sigFigs(this.buyerNetworkFee, 4) + " " + this.currencyFormat(this.buyerNetworkCurrency);
          } else {
              return "N/A";
          }
        }
      },

      buyerNetworkFeeInRefCurrency: function() {
        if ( !isNaN(this.buyerNetworkFee) && !this.buyerNetworkFeeLoading ) {
          const currencyFrom = toCurrencyCode(this.buyerNetworkCurrency)[0];
          const currencyTo = toCurrencyCode(this.refCurrency)[0];
          return this.convert(this.buyerNetworkFee, currencyFrom, currencyTo);
        } else {
          return NaN;
        }
      },

      buyerNetworkFeeInRefCurrencyFormatted: function() {
        if ( !isNaN(this.buyerNetworkFeeInRefCurrency) ) {
          return "~" + this.sigFigs(this.buyerNetworkFeeInRefCurrency, 4) + " " + this.currencyFormat(this.refCurrency);
        } else {
          return "-";
        }
      },

      sellerNetworkFeeInRefCurrency: function() {
        if ( !isNaN(this.sellerNetworkFee) ) {
          const currencyFrom = toCurrencyCode(this.sellerNetworkCurrency)[0];
          const currencyTo = toCurrencyCode(this.refCurrency)[0];
          return this.convert(this.sellerNetworkFee, currencyFrom, currencyTo);
        } else {
          return NaN;
        }
      },

      buyerNetworkFeeFactorInRefCurrency: function() {
        if ( !isNaN(this.buyerNetworkFeeInRefCurrency) && !isNaN(this.sellerNetworkFeeInRefCurrency) ) {
          return ((this.sellerNetworkFeeInRefCurrency - this.buyerNetworkFeeInRefCurrency) / 2);
        } else {
          return NaN;
        }
      },


      buyerNetworkFeeFactorInAuctionCurrency: function() {
        if ( !isNaN(this.buyerNetworkFeeFactorInRefCurrency) ) {
          const currencyFrom = toCurrencyCode(this.refCurrency)[0];
          const currencyTo = toCurrencyCode(this.auctionCurrency)[0];
          return this.convert(this.buyerNetworkFeeFactorInRefCurrency, currencyFrom, currencyTo);
        } else {
          return NaN;
        }
      },

      buyerNetworkFeeFactorInEstimatedCurrency: function() {
        if ( !isNaN(this.buyerNetworkFeeFactorInRefCurrency) ) {
          const currencyFrom = toCurrencyCode(this.refCurrency)[0];
          const currencyTo = toCurrencyCode(this.buyerAmountEstimateCurrency)[0];
          return this.convert(this.buyerNetworkFeeFactorInRefCurrency, currencyFrom, currencyTo);
        } else {
          return NaN;
        }
      },

    },
    watch: {
      buyerCurrency: function(newVal) {
        if ( this.buyerAmountEstimateCurrency !== this.refCurrency ) {
          this.buyerAmountEstimateCurrency = newVal;
          this.debouncedLoadEstimateFee();
        }
      },
    },
    mounted() {
      this.loadCurrencyRates();
      initModalBlock();
      initTabs();
      this.initCheckAuctionStateRoutine();

      this.debouncedLoadEstimateFee = _.debounce(this.loadEstimateFee, 500);

      setInterval(this.debouncedLoadEstimateFee, 60000);
    }
  });
  return { auctionSinglePageApp }
}
