<template>
  <li class="row b-grey bb-grey-marketplace desc-color back-white pt-4 pb-3 justify-content-between mb-2 pl-10">
  <div class="col-12 col-md-10">
    <div class="row justify-content-between mb-3 mb-md-1">

      <div class=" col col-md-8">
        <div class="row">

          <span class="col-4 col-md-4 d-none d-md-flex justify-content-start mb-sm-0 pr-0 pl-0">
            <div class="row">
              <div class="col col text-left ">
                <div class="d-flex align-items-start justify-content-start justify-content-md-start pr-3 pr-md-0">
                  <div class="seller-info text-left pl-0 pl-xl-3">
                      <a href="#" class="ellipsis-one-lines max-width-name font-medium text-color-blue pt-0 font-13 lh-marketplace" :class="{ 'user-online': result.user.online }"><u>{{ result.user.formatted_name }}</u></a>
                      <p class=" font-11 mb-0">{{ result.user.formatted_trades }}</p>
                  </div>
                </div>
              </div>
            </div>
          </span>

          <span class="col-12 col-md-4 mb-sm-0 d-flex pr-0 pt-md-0">
            <div class="row">
              <div class="col-md-12 col text-left pr-0 pl-0 max-width-available">
                <span class=" text-bold font-13 lh-marketplace-md pt-seller available" v-html="result.formated_amount"></span>
                <template v-if="result.selling_limits">
                  <div class="font-10-adaptive">Buy limit: {{ result.formatted_min_limit_in_default_currency }} - {{ result.formatted_max_limit_in_default_currency }}</div>
                </template>
                <template v-else>
                  <div class="font-10-adaptive">Buy limit: Take all</div>
                </template>
                <div class="font-10-adaptive text-color-footer" v-if="result.forensic_check">Forensic report: 75</div>
              </div>
            </div>
          </span>

          <span class="col-4 col-md-4 d-none d-md-flex mb-sm-0 pl-md-0 justify-content-left">
            <div class="col-md-12 col text-left pl-0 max-width-rate">
              <div class="text-color-blue text-bold font-17" v-html="result.formatted_margin_discount_exchange_rate"></div>
              <template v-if="result.margin_discount > 0">
                <div class="font-10-adaptive">Margin: {{ result.formatted_margin_discount }}%</div>
              </template>
              <template v-if="result.margin_discount < 0">
                <div class="font-10-adaptive">Discount: {{ result.formatted_margin_discount }}%</div>
              </template>
              <template v-else>
                <div class="font-10-adaptive">Average market price</div>
              </template>
            </div>
          </span>

        </div>
      </div>


        <span class="col-3 col-md-4 d-none d-md-flex mb-sm-0 justify-content-center pl-0 pr-0">
          <div class="col-12 mb-sm-0 d-md-block mb-0">
            <div class="row">
              <div class="col-12 text-bold d-md-none pl-0 pr-0">Pay in:</div>
              <div class="col-12 col-md-12 font-pay-in col text-left accepted-currencies pay-in-formatting pl-0 marketplace-pay-in" v-html="result.formatted_accepted_currencies">
              </div>
              <div class="col-md-12 col font-10-adaptive text-color-footer" v-if="result.forensic_check">Min. forensic rate: {{ result.min_forensic_rate }}</div>
            </div>
          </div>
        </span>


        <span class="col-4 d-flex d-md-none mb-sm-0 flex-column">
          <div class="row justify-content-left">
            <div class="d-inline-block">
              <div class="col-md-12 col text-left d-inline-block pr-0">
                <div class="text-color-blue text-bold font-16">{{ result.formatted_margin_discount_exchange_rate }}</div>
                <template v-if="result.margin_discount > 0">
                  <div class="font-10-adaptive">Margin: {{ result.formatted_margin_discount }}%</div>
                </template>
                <template v-if="result.margin_discount < 0">
                  <div class="font-10-adaptive">Discount: {{ result.formatted_margin_discount }}%</div>
                </template>
                <template v-else>
                  <div class="font-10-adaptive">Average market price</div>
                </template>
              </div>
            </div>
          </div>
        </span>

        <span class="col-4 d-flex d-md-none mb-sm-0 flex-column">
          <div class="row content-in-middle justify-content-center ">
            <div class="d-inline-block">
              <div class="col-md-12 text-left text-bold pr-0">
                <a :href="result.auction_path" class="d-flex align-items-center justify-content-center trn btn btn-marketplace-checkout btn-color-bg-t rounded-pill"><span>Buy</span></a>
                <div class="font-11 text-color-footer d-none d-md-block">Ends in <%= result["formatted_finishes_at"] %></div>
              </div>
            </div>
          </div>
        </span>

    </div>

    <div class="row align-items-start justify-content-between d-md-none">

      <span class="col-4 col-md-3 mb-sm-0 d-flex justify-content-start pr-0 pt-2">
        <div class="row">
          <div class="col col-md-12 col text-left pl-0">
            <div class="row align-items-end justify-content-start justify-content-md-start pr-3 pr-md-0  ml-lg-2">

                <div class="seller-info text-left col-md-12 col-lg-12 col-xl-12 pl-xl-3 max-width-name">
                    <a href="#" class="ellipsis-one-lines font-medium text-color-blue pt-0 font-13 lh-marketplace user-online"><u>{{ result.user.formatted_name }}</u></a>
                    <p class=" font-11 mb-0">{{ result.user.formatted_trades }}</p>
                </div>

            </div>
          </div>
        </div>
      </span>


      <div class="col-2 d-none d-md-flex pl-0 pr-0 justify-content-right">
        <div class="col d-inline-block text-left text-bold">
          <a :href="result.auction_path" class="d-flex align-items-center justify-content-center trn btn btn-marketplace-checkout btn-color-bg-t rounded-pill mb-2 ml-0"><span>Buy</span></a>
          <div class="font-11 text-color-footer d-none d-md-block">Ends in {{ result.formatted_finishes_at }}</div>
        </div>
      </div>

      <div class="col-8 d-md-none col-md-3 mb-sm-0 mb-0">
        <div class="row">
          <div class="col-12 text-bold d-md-none pr-0">Pay in:</div>
          <div class="col-12 col-md-12 font-pay-in col text-left accepted-currencies pay-in-formatting marketplace-pay-in" v-html="result.formatted_accepted_currencies">
          </div>
          <div class="col-md-12 col font-10-adaptive text-color-footer" v-if="result.forensic_check">Min. forensic rate: {{ result.min_forensic_rate }}</div>
        </div>
      </div>
    </div>


  </div>

  <div class="col-md-2 d-none d-md-block pl-0">
    <span class=" d-flex mb-sm-0 justify-content-center">
      <div class="row content-in-middle  ">
        <div class="d-inline-block">
          <div class="col-md-12 text-left text-bold pr-0 text-center pl-0">
            <a :href="result.auction_path" class="d-flex align-items-center justify-content-center trn btn btn-marketplace-checkout btn-color-bg-t rounded-pill mb-2 ml-0"><span>Buy</span></a>
            <div class="font-11 text-color-footer d-none d-md-block">Ends in {{ result.formatted_finishes_at }}</div>
          </div>
        </div>
      </div>
    </span>
  </div>

</li>

</template>

<script>

import Moment from "moment";
import Pluralize from 'pluralize';

export default {
  name: 'SearchResult',
  props: {
    result: {
      type: Object,
      required: true
    }
  }
}
</script>
