<template>
  <span></span>
</template>

<script>

export default {
  name: 'CountdownLight',
  props: {
    finishesAt: {
      type: String,
      require: true,
    },
    freeze: {
      type: Boolean,
      default: false,
    },
    skipDays: {
      type: Boolean,
      default: false,
    },
    redirectTo: {
      type: String,
      require: true,
    }
  },
  mounted() {
    const vm = this;

    var countdown = $(vm.$el).countdown(vm.finishesAt, function(event) {
      if (event.elapsed) {
        window.location.href = vm.redirectTo;
      }

      if (vm.skipDays) {
        var timerHTML = '%H:%M:%S';
      } else {
        var timerHTML = '%D:%H:%M:%S';
      }

      $(vm.$el).html(event.strftime(timerHTML));
    });

    if (vm.freeze) $(vm.$el).countdown("stop");
  }
}
</script>
