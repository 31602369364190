<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header font-20">
          <h5 class="modal-title">Thank you for great feedback!</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="feedback-form">
            <div class="bg-color-w bg-mobile-fix-lr p_20 p-sm-20 mb20 text-center">
              <img :src="require('images/new/success-icon.svg')" class="image"></img>
            </div>
            <div class="-no-fix-width -small-padding font-15 text-color-dark">
              <b>Can you also share your feedback on TrustPilot?</b> We strive to bring the best expirience to our users.
              That will take <b>only 1 minute of your time</b> and will help us to bring more great deals to you in the future!
            </div>
          </div>
          <div class="thankyou-message" style="display: none;">
            <div class="bg-color-w bg-mobile-fix-lr p_30 p-sm-20 mb20 text-center">
              <img :src="require('images/new/success-icon.svg')" class="image"></img>
            </div>
            <div class="-no-fix-width -small-padding font-15 text-color-dark">
              Thank you for your feedback. We will review it and will work on to improve your expirience in the future.
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <a type="button" v-show="!feedbackGiven" @click="trustpilotClicked()" class="btn btn-primary" href="https://www.trustpilot.com/evaluate/whalesheaven.com" target="_blank">
            Rate on Trustpilot
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../utils/event_bus.js";

export default {
  name: 'TrustPilotAskModal',
  props: {
    dealTransactionFuzzyId: {
      type: String,
      require: true,
    },
    rating: {
      type: Number,
      required: true
    },
  },
  data: function () {
    return {
      feedbackGiven: false,
    };
  },
  methods: {
    trustpilotClicked: function() {
      const vm = this;

      $.ajax({
        type: "POST",
        url: `/deal_transactions/${this.dealTransactionFuzzyId}/give_feedback`,
        data: {
          deal_rating: {
            feedback: "Trustpilot clicked"
          }
        },
        dataType: "JSON",
        async: false,
        success: function(response, textStatus) {
          if ( response.status === 'OK' ) {
            vm.feedbackGiven = true;
            $(this.$el).modal('hide');
            eventBus.$emit(`feedbackGiven`);
          }
        },
        error: function(xhr, response) {
          // What shall we do here?
        },
      }, 0);
    },

    openModal: function() {
      const vm = this;

      $(vm.$el).modal('show');
    },
  },
  mounted() {
    $(this.$el).on('hidden.bs.modal', function () {
      eventBus.$emit(`feedbackSkipped`);
    });
  }
}
</script>
