<template>
  <div>
    <div class="row justify-content-center ml-auto mr-auto">
      <div class="pr-1 rate-star rate-star-1" :class="{ 'mouseover' : hoverStar >= 1 }" @mouseenter="setHover(1)" @mouseleave="setHover(0)">
        <button @click="giveRating(1)" :disabled="rateGiven" :class="{ disabled: rateGiven }">
          <img :src="require('images/new/star-grey.svg')" class="ml-auto mr-auto star-icon">
          <img :src="require('images/new/star-blue.svg')" class="ml-auto mr-auto star-icon hovered">
        </button>
      </div>
      <div class="pr-1 rate-star rate-star-2" :class="{ 'mouseover' : hoverStar >= 2 }" @mouseenter="setHover(2)" @mouseleave="setHover(0)">
        <button @click="giveRating(2)" :disabled="rateGiven" :class="{ disabled: rateGiven }">
          <img :src="require('images/new/star-grey.svg')" class="ml-auto mr-auto star-icon">
          <img :src="require('images/new/star-blue.svg')" class="ml-auto mr-auto star-icon hovered">
        </button>
      </div>
      <div class="pr-1 rate-star rate-star-3" :class="{ 'mouseover' : hoverStar >= 3 }" @mouseenter="setHover(3)" @mouseleave="setHover(0)">
        <button @click="giveRating(3)" :disabled="rateGiven" :class="{ disabled: rateGiven }">
          <img :src="require('images/new/star-grey.svg')" class="ml-auto mr-auto star-icon">
          <img :src="require('images/new/star-blue.svg')" class="ml-auto mr-auto star-icon hovered">
        </button>
      </div>
      <div class="pr-1 rate-star rate-star-4" :class="{ 'mouseover' : hoverStar >= 4 }" @mouseenter="setHover(4)" @mouseleave="setHover(0)">
        <button @click="giveRating(4)" :disabled="rateGiven" :class="{ disabled: rateGiven }">
          <img :src="require('images/new/star-grey.svg')" class="ml-auto mr-auto star-icon">
          <img :src="require('images/new/star-blue.svg')" class="ml-auto mr-auto star-icon hovered">
        </button>
      </div>
      <div class="pr-1 rate-star rate-star-5" :class="{ 'mouseover' : hoverStar >= 5 }" @mouseenter="setHover(5)" @mouseleave="setHover(0)">
        <button @click="giveRating(5)" :disabled="rateGiven" :class="{ disabled: rateGiven }">
          <img :src="require('images/new/star-grey.svg')" class="ml-auto mr-auto star-icon">
          <img :src="require('images/new/star-blue.svg')" class="ml-auto mr-auto star-icon hovered">
        </button>
      </div>
    </div>

    <div class="row justify-content-center mt-10 ml-auto mr-auto" v-show="rateGiven && feedbackGiven">
      Thank you!
    </div>

    <div class="row justify-content-center mt-10 ml-auto mr-auto" :class="{ 'fade-in': feedbackSkipped }" v-show="rating > 0 && !feedbackGiven || feedbackSkipped">
      <a type="button" class="btn btn-primary" @click="trustpilotClicked" href="https://www.trustpilot.com/evaluate/whalesheaven.com" target="_blank" v-if="rateStar >= 4">
        Rate on Trustpilot
      </a>

      <a href="#" @click="openFeedbackModal" class="text-color-blue underline-blue" v-if="rateStar < 4">Give feedback</a>
    </div>

    <trust-pilot-ask-modal
      v-if="!hasFeedback"
      ref="trustPilotModal"
      :deal-transaction-fuzzy-id="dealTransactionFuzzyId"
      :rating="rateStar"
    ></trust-pilot-ask-modal>

    <feedback-form-modal
      v-if="!hasFeedback"
      ref="feedbackFormModal"
      :deal-transaction-fuzzy-id="dealTransactionFuzzyId"
      :rating="rateStar"
    ></feedback-form-modal>
  </div>
</template>

<script>
import { eventBus } from "../utils/event_bus.js";
import TrustPilotAskModal from './trust_pilot_ask_modal.vue';
import FeedbackFormModal from './feedback_form_modal.vue';

export default {
  name: 'DealRateStars',
  components: {
    TrustPilotAskModal,
    FeedbackFormModal,
  },
  props: {
    dealTransactionFuzzyId: {
      type: String,
      require: true,
    },
    rating: {
      type: Number,
      required: false,
    },
    hasFeedback: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      rateStar: 0,
      hoverStar: 0,
      rateGiven: false,
      feedbackGiven: false,
      feedbackSkipped: false,
    };
  },
  methods: {
    submitRating: function () {
      const vm = this;
      $.ajax({
        type: "POST",
        url: `/deal_transactions/${this.dealTransactionFuzzyId}/give_rating`,
        data: {
          rating: this.rateStar,
        },
        dataType: "JSON",
        async: false,
        success: function(response, textStatus) {
          if ( response.status === 'OK' ) {
            // Show success message here
          }
        },
        error: function(xhr, response) {
          vm.rateGiven = false;
          console.log("Error: " + xhr.responseJSON.error);
        },
      }, 0);
    },

    giveRating: function(starNumber) {
      if ( !this.rateGiven ) {
        this.rateStar = starNumber;
        this.hoverStar = starNumber;
        this.rateGiven = true;

        if ( starNumber >= 4 ) {
          this.$refs.trustPilotModal.openModal();
        } else {
          this.$refs.feedbackFormModal.openModal();
        }

        this.submitRating();
      }
    },

    trustpilotClicked: function() {
      const vm = this;

      $.ajax({
        type: "POST",
        url: `/deal_transactions/${this.dealTransactionFuzzyId}/give_feedback`,
        data: {
          deal_rating: {
            feedback: "Trustpilot clicked"
          }
        },
        dataType: "JSON",
        async: false,
        success: function(response, textStatus) {
          if ( response.status === 'OK' ) {
            eventBus.$emit(`feedbackGiven`);
          }
        },
        error: function(xhr, response) {
          // What shall we do here?
        },
      }, 0);
    },

    openFeedbackModal: function(e) {
      e.preventDefault();

      if ( this.rateStar < 4 ) {
        this.$refs.feedbackFormModal.openModal();
      }
    },

    setHover: function(starNumber) {
      if ( !this.rateGiven ) {
        this.hoverStar = starNumber;
      }
    },
  },
  mounted() {
    const vm = this;

    if (this.rating > 0) {
      this.rateStar = this.rating;
      this.hoverStar = this.rating;
      this.rateGiven = true;
    }

    this.feedbackGiven = this.hasFeedback;

    eventBus.$on(`feedbackGiven`, () => {
      vm.feedbackGiven = true;
      vm.feedbackSkipped = false;
    });

    eventBus.$on(`feedbackSkipped`, () => {
      if (!this.feedbackGiven) vm.feedbackSkipped = true;
    });
  }
}
</script>

<style scoped>
.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
