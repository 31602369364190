<template>
  <canvas></canvas>
</template>

<script>

// import moment from 'moment';
import Chart from 'chart.js/dist/Chart.js';
import randomColor from 'randomcolor/randomColor';

export default {
  name: 'PriceHistory',
  props: {
    currencyFrom: {
      type: String,
      required: true
    },
    acceptableCurrencies: {
      type: Array,
      default: []
    }
  },
  data: function() {
    return {
      canvas: null,
      chart: null,
      canvasLoaded: false,
      dataset: {},
      scales: {},
      selectedPairs: [],
      chartUpdatingCounter: 0,
      callbacks: [],
    }
  },
  methods: {
    init: function(elementId, onLoadingCallback) {
      this.setCanvas(elementId);
      if (typeof onLoadingCallback == "function") this.callbacks.push(onLoadingCallback);
      this.renderInitial();
    },

    renderInitial: function() {
      // Load empty chart
      this.loadCanvas("", [], []);
      this.canvasLoaded = true;
      this.executePendingCallbacks();
    },

    rerender: function(currencyFrom, currencyTo) {
      this.selectedPairs = [];
      this.chart.reset();
      this.requestDataSet(currencyFrom, currencyTo);
    },

    uprender: function(currencyFrom, currencyTo) {
      this.requestDataSet(currencyFrom, currencyTo);
    },

    getColor: function() {
      const  colorHues = ["red", "orange", "yellow", "green", "blue", "purple", "pin"];
      const  hueTone = colorHues[this.selectedPairs.length];
      return randomColor({hue: hueTone});
    },

    setCanvas: function(elementId) {
      this.canvas = $(elementId);
    },

    currencyFormat: function(currencyCode) {
      const  splitedCurrency = currencyCode.split(/[-:]/);
      const  formattedCode = splitedCurrency[0];
      // if ( splitedCurrency.length > 1 ) {
      //   formattedCode += ' (';
      //   if (splitedCurrency[1]) formattedCode += splitedCurrency[1];
      //   if (splitedCurrency[2]) formattedCode += ', ' + splitedCurrency[2];
      //   formattedCode += ')';
      // }
      return formattedCode;
    },

    requestDataSet: function(currencyFrom, currencyTo) {
      const  vm = this;
      const  currencyPair = this.currencyFormat(currencyFrom) + "-" + this.currencyFormat(currencyTo);
      let    historyRateUrl = "/api_v1/history_rates";
      historyRateUrl = historyRateUrl + "?from=" + currencyFrom + "&to=" + currencyTo;

      if (typeof vm.dataset[currencyPair] == 'undefined') {
        vm.chartUpdatingCounter++; // Set a lock to message count updating

        $.getJSON(
            historyRateUrl,
            function(data) {

                if ( data.rates ) {
                    const  labels = [], values = [];
                    for(let i = 0; i < data.rates.length; i++) {
                      values.push(data.rates[i].rate);
                      labels.push(moment(data.rates[i].date, "YYYY-MM-DD").format('ll'));
                    }

                    vm.chart.config.data.labels = labels;

                    if (vm.canvasLoaded == false) {
                      vm.loadCanvas(currencyPair, labels, values);
                      vm.canvasLoaded = true;
                    } else {
                      vm.loadPoints(currencyPair, labels, values);
                    }
                }
                vm.chartUpdatingCounter--;
                if (vm.chartUpdatingCounter == 0) {
                  vm.chartUpdate();
                  vm.executePendingCallbacks();
                }
            }
        );
      } else {
          vm.loadChart(currencyPair);
      }
    },

    loadChart:  function(currencyPair) {
      const  vm = this;
      const  dataSet = vm.dataset[currencyPair];
      const  scale = vm.scales[currencyPair];

      this.updateCanvasData(currencyPair, dataSet, scale);
      this.chartUpdate();
      vm.executePendingCallbacks();
    },

    loadPoints:  function(currencyPair, labels, data) {
      const  vm = this;
      const  currencyTo = currencyPair.split('-')[1];

      const color = vm.getColor();
      const dataSet = {
        label: currencyPair,
        yAxisID: currencyPair,
        backgroundColor: Chart.helpers.color(color).alpha(0.5).rgbString(),
        borderColor: color,
        data: data,
        type: 'line',
        pointRadius: 0,
        fill: false,
        lineTension: 0,
        borderWidth: 2
      }

      const scale = jQuery.extend(true, {}, vm.chart.config.options.scales.yAxes[0]);
      scale.id = currencyPair;
      scale.display = true;
      scale.scaleLabel = { display: true };

      vm.updateCanvasData(currencyPair, dataSet, scale);
      this.chartUpdate()
    },

    executePendingCallbacks: function() {
      let callback;
      while(this.callbacks.length > 0) {
        callback = this.callbacks.pop();
        callback();
      }
    },

    updateCanvasData: function(currencyPair, dataSet, scale) {
      const  vm = this;

      if (vm.selectedPairs.length == 0) {
        scale.display = false;
        vm.selectedPairs = [currencyPair];
        vm.chart.config.data.datasets = [dataSet];
        vm.chart.config.options.scales.yAxes = [scale];
      } else {
        if (vm.selectedPairs.includes(currencyPair)) return;
        scale.display = false;
        vm.chart.config.options.scales.yAxes[0].display = false; // if multiple charts disable access
        vm.chart.config.options.scales.yAxes[0].showLines = true;
        vm.selectedPairs.push(currencyPair);
        vm.chart.config.data.datasets.push(dataSet);
        vm.chart.config.options.scales.yAxes.push(scale);
      }
    },

    chartUpdate: function() {
      this.chart.update();
    },

    loadCanvas: function(currencyPair, labels, data) {
      const  vm = this;
      const  ctx = vm.canvas[0].getContext('2d');
      const  currencyTo = currencyPair.split('-')[1];

      ctx.canvas.width = 700;
      ctx.canvas.height = 300;

      const color = vm.getColor();
      const dataSet = {
        label: currencyPair,
        backgroundColor: Chart.helpers.color(color).alpha(0.5).rgbString(),
        borderColor: color,
        yAxisID: currencyPair,
        data: data,
        type: 'line',
        pointRadius: 0,
        fill: false,
        lineTension: 0,
        borderWidth: 2
      }
      vm.dataset[currencyPair] = dataSet;

      const yScale = {
        display: false,
        id: currencyPair,
        offset: false,
        position: "left",
        scaleLabel: {
          display: false
        },
        ticks: {
            display: false
        },
        type: "linear"
      }
      vm.scales[currencyPair] = yScale;

      vm.selectedPairs = [currencyPair];

      const  cfg = {
        type: 'line',
        data: {
          labels: labels,
          datasets: [dataSet]
        },
        options: {
          responsive: true,
          tooltips: {
            position: 'nearest',
            mode: 'index',
            intersect: false,
          },
          animation: {
            duration: 0
          },
          hover: {
            animationDuration: 0
          },
          responsiveAnimationDuration: 0,
          scales: {
            yAxis: [yScale]
          }
        }
      };

      vm.chart = new Chart(ctx, cfg);
    }
  },
  computed: {
    isInitialized: function() {
      return this.chart != null;
    },
  },
  mounted() {
    const vm = this;

    vm.init(vm.$el, function() {
      for(let  i = 0; i < vm.acceptableCurrencies.length; i++) {
        if ( i == 0 ) vm.rerender(vm.currencyFrom, vm.acceptableCurrencies[i]);
        else vm.uprender(vm.currencyFrom, vm.acceptableCurrencies[i]);
      }
    });
  }
}
</script>
