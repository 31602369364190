<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header font-20">
          <h5 class="modal-title">You {{ currencySymbol }} top up address</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="qr qr-canvas"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'utils/qrcode';

export default {
  name: 'QrCodeModal',
  props: {
    currencySymbol: {
      type: String,
      required: true
    },
    topupAddress: {
      type: String,
      required: true
    },
  },
  data: function() {
    return {
      qrCodeInitialized: false,
    }
  },
  methods: {
    openModal: function() {
      $(this.$el).modal('show');
    }
  },
  mounted() {
    if ( !this.qrCodeInitialized ) {
      this.qrCodeInitialized = true;
      const canvas = this.$el.querySelector('.qr-canvas');
      new QRCode(canvas, {
        text: this.topupAddress,
        width: 220,
        height: 220,
      });
    }
  }
}
</script>
