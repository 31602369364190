<template>
  <button
    class="g-fix-btn-width font-14 font-sm-14 trn btn btn-outline-primary rounded-pill btn-color-bg -orange"
    data-toggle="tooltip"
    :title="disabledReason"
    @click="showLoginModel"
  >
    <span>Create new sell offer</span>
  </button>
</template>

<script>
export default {
  name: "CreateAuctionRedirectButton",
  props: {
    disabledReason: String,
  },
  methods: {
    showLoginModel: function() {
      $("#loginModal").modal("show");
    },
  },
};
</script>
