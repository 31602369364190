<template>
  <div class="p_30 transactions_details bg-light-grey mt-60">
    <div class="row font-15 font-sm-13 font-bold mb20">
      <div class="col-3 col-sm-6 col-md-5 col-lg-6 ellipsis-one-lines incoming-sm pr-0">Transaction</div>
      <div class="col-3 col-sm-6 col-md-5 col-lg-6 ellipsis-one-lines incoming-lg">Recent incoming transactions</div>
      <div class="col-6 col-sm-4 ellipsis-one-lines">Amount</div>
      <div class="col-3 col-sm-2 col-md-3 col-lg-2 ellipsis-one-lines pr-0">Confirmations</div>
    </div>

    <div class="transactions_body" v-if="transactions.length">
      <div class="row font-15 text-color-dark mb15" v-for="transaction in transactions" :key="transaction.transaction_hash">
        <div class="col-6 text-color-blue ellipsis-one-lines" v-html="referenceLink(transaction)"></div>
        <span class="col-4 curr-format" v-html="transactionAmount(transaction)"></span>
        <div class="col-2 ellipsis-one-lines">{{ confirmationsCount(transaction) }}</div>
      </div>
    </div>
    <div class="transactions_body" v-else>
      <div class="bg-light-grey pt50 pb20 pl30 pr30 text-center">
        <div class="font-17 font-sm-15 text-color-dark big-lh mb-4">No transactions discovered yet</div>
        <div class="font-17 font-sm-15 text-color-dark mb20 big-lh">It may take up to 30 seconds for our platform to discover changes on blockchain.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { sigFigsString, currencyFormat } from "utils/formatting";

export default {
  name: 'TransactionsTable',
  data: function() {
    return {
      transactions: [],
      minConfirmations: 0,
    };
  },
  methods: {
    setTransactions: function(newTransactions) {
      this.transactions = newTransactions;
    },

    setMinConfirmations: function(newMinConfirmations) {
      this.minConfirmations = newMinConfirmations;
    },

    referenceLink: function(transactionData) {
      let referenceLink;

      if (transactionData.blockchain == "Bitcoin") {
        referenceLink = "https://live.blockcypher.com/btc/tx/";
      } else if (transactionData.blockchain == "BitcoinTestnet") {
        referenceLink = "https://live.blockcypher.com/btc-testnet/tx/";
      } else if (transactionData.blockchain == "Litecoin") {
        referenceLink = "https://live.blockcypher.com/ltc/tx/";
      } else if (transactionData.blockchain == "Dogecoin") {
        referenceLink = "https://live.blockcypher.com/doge/tx/";
      } else if (transactionData.blockchain == "BitcoinCash") {
        referenceLink = "https://blockchair.com/bitcoin-cash/";
      } else if (transactionData.blockchain == "Ethereum") {
        referenceLink = "https://etherscan.io/tx/";
      } else if (transactionData.blockchain == "EthereumRopsten") {
        referenceLink = "https://ropsten.etherscan.io/tx/";
      } else if (transactionData.blockchain == "EthereumGoerli") {
        referenceLink = "https://goerli.etherscan.io/tx/";
      } else if (transactionData.blockchain == "BinanceSmartChain") {
        referenceLink = "https://bscscan.com/tx/";
      } else if (transactionData.blockchain == "Polygon") {
        referenceLink = "https://polygonscan.com/tx/";
      } else if (transactionData.blockchain == "Avalanche") {
        referenceLink = "https://snowtrace.io/tx/";
      }

      referenceLink = "<a href='" +
        referenceLink +
        transactionData.transaction_hash +
        "/' target='_blank'>" +
        truncate(transactionData.transaction_hash, 31, "......") +
        "</a>";

      if ( transactionData.confirmations >= this.minConfirmations ) {
        referenceLink = `<img src="${require('images/new/ready-icon-big.svg')}" class=""></img>`+ referenceLink;
      } else {
        referenceLink = `<img src="${require('images/new/load-icon-big.svg')}" class=" load-icon-big"></img>` + referenceLink;
      }

      return referenceLink;
    },

    transactionAmount: function(transactionData) {
      return `${this.sigFigsString(transactionData.amount_coins, 5, "ceil")} ${currencyFormat(transactionData.currency)}`;
    },

    confirmationsCount: function(transactionData) {
      if ( transactionData.confirmations >= this.minConfirmations ) {
        return `${this.minConfirmations}+`;
      } else {
        return transactionData.confirmations + " out of " + this.minConfirmations;
      }
    },
    ...{ sigFigsString, currencyFormat },
  },
}
</script>
