<template>
  <div>
    <div v-if="!isLoading">
      <div v-if="hasBalances" v-for="(value, currency) in balances">
        {{ sigFigs(value, 4) }} {{ currency }}
      </div>
      <div v-if="!hasBalances">
        -
      </div>
    </div>
    <div v-if="isLoading">
      ...
    </div>
  </div>
</template>

<script>
import { sigFigs, currencyFormat } from '../utils/formatting'

export default {
  name: 'TsWalletBalance',
  props: {
    tsWalletId: String
  },
  data: function() {
    return {
      balances: {},
      isLoading: true,
    }
  },
  methods: {
    requestBalanceData: function() {
      const vm = this;

      $.getJSON(
        `/trading_multisigs/${this.tsWalletId}/balance_state`,
        function(data) {
          if ( data.balances ) {
            vm.balances = data.balances;
            vm.isLoading = false;
          }
        }
      );
    },
    sigFigs: function(value, digits) {
      return sigFigs(value, digits);
    }
  },
  computed: {
    hasBalances: function() {
      return Object.keys(this.balances).length > 0;
    }
  },
  mounted() {
    var vm = this;

    vm.$nextTick(function() {
      vm.requestBalanceData();
    });
  }
}
</script>
