<template>

  <fragment>
    <div class="row" style="margin-left: 0">
      <div class="col-sm-6 col-xs-12">
        <a href="#" class="tab-advenced-link" :class="{ '-toggle': expanded }" @click.prevent="expanded = !expanded" :id="accordionId" :href="'#'+panelId" :aria-controls="panelId">
          <span>{{ elementNaming }}</span>
        </a>
      </div>
    </div>

    <div :id="panelId" class="tab-advenced-content" :class="{ '-toggle': expanded }" role="tabpanel" :aria-labelledby="accordionId">
      <slot :expanded="expanded"></slot>
    </div>
  </fragment>

</template>

<script>


export default {
  name: 'Accordion',
  props:  {
    elementId: String,
    elementNaming: String,
    initialExpanded: {
      type: Boolean,
      default: () => { return false; }
    }
  },
  data: function () {
    return {
      expanded: false
    }
  },
  computed: {
    accordionId: function () {
      return this.elementId + '-panel-heading';
    },
    panelId: function () {
      return this.elementId + '-panel';
    },
  },
  methods: {
    clicked($event) {
        this.$emit('click', $event);
    }
  },
  mounted: function () {
    this.expanded = this.initialExpanded;
  },
}
</script>
