<template>

  <div class="form-group error-input mb30" :class="[(hasErrors ? '-red' : ''), (isSignatureValid ? '-green' : '')]">
    <input type="hidden" :value="recordId" :name="idElementName" :id="idElementId" v-if="recordId">
    <input type="hidden" :name="signatureElementName" :id="signatureElementId" v-model="currenctSignature">
    <label :for="elementId" class="font-17 font-sm-15 font-bold text-color-dark ellipsis-one-lines mb-2">{{ humanizedBlockchain }}</label>
    <div class="font-12 text-color-footer mt8 ellipsis-one-lines" v-html="currenciesFormatted" v-if="areCurrenciesPresent"></div>
    <div class="destination-address-wrapper">
      <input type="text" :disabled="isSignatureValid" :placeholder="placeholder" :name="elementName" :id="elementId" :pattern="addressRegexp" v-model="destination" class="form-control g-clear" autocomplete="off" required="true">
      <input type="hidden" v_if="isSignatureValid" :name="elementName"  v-model="destination">
      <button class="change-btn desc-color" v-show="isSignatureValid" @click="changeDestination">Change</button>
    </div>
    <errors-list :errors="errors"></errors-list>
  </div>

</template>

<script>
import { ec } from 'elliptic'
import { sha256 } from 'js-sha256';
import ErrorsList from './errors_list'

export default {
  name: 'DestinationAddress',
  components: {
    ErrorsList,
  },
  props:  {
    elementId: String,
    elementName: String,
    addressSample: String,
    recordId: String,
    blockchain: String,
    destinationAddress: String,
    addressRegexp: String,
    signature: String,
    currencies: Array,
    initErrors: Array,
  },
  data: function () {
    return {
      expanded: false,
      destination: "",
      currenctSignature: "",
      errors: [],
    }
  },
  methods: {
    changeDestination: function() {
      this.destination = "";
      this.currenctSignature = "";
    },
    setSignature: function(newSignature) {
      this.currenctSignature = newSignature;
    },
    isAddressMatchRegexp: function() {
      const regexp = new RegExp(this.addressRegexp, 'g');
      return regexp.test(this.destination);
    },
    updateErrors: function() {
      let errors = [];

      if ( !this.isDestinationAddressPresent ) errors.push("Please provide destination adderss");
      if ( this.isDestinationAddressPresent && !this.isSignaturePresent ) errors.push("Destination requires verification");
      if ( this.isDestinationAddressPresent && this.isSignaturePresent && !this.isSignatureValid ) errors.push("Destination requires verification");
      if ( this.isDestinationAddressPresent && this.isSignaturePresent && this.isSignatureValid ) errors.push("Destination verified");

      this.errors = errors;
    }
  },
  computed: {
    isDestinationAddressPresent: function() {
      return this.destination.length > 0 && this.isAddressMatchRegexp();
    },
    isSignaturePresent: function() {
      return this.currenctSignature.length >= 140 && this.currenctSignature.length <= 148;
    },
    placeholder: function() {
      return "eg. " + this.addressSample;
    },
    areCurrenciesPresent: function() {
      return this.currencies && this.currencies.length > 0;
    },
    currenciesFormatted: function() {
      return "Receive " + this.currencies.join(", ");
    },
    idPrefix: function () {
      return this.elementId.replace("_destination_address", "");
    },
    namePrefix: function () {
      return this.elementName.replace("[destination_address]", "");
    },
    idElementId: function () {
      return this.idPrefix + "_destination_address";
    },
    idElementName: function() {
      return this.namePrefix + "[id]"
    },
    signatureElementId: function () {
      return this.idPrefix + "_signature";
    },
    signatureElementName: function() {
      return this.namePrefix + "[signature]"
    },
    hasErrors: function() {
      return this.errors.length > 0;
    },
    humanizedBlockchain: function() {
      switch(this.blockchain) {
        // case "OmniLayer":
        //   return "Bitcoin (OmniLayer)";
        case "BitcoinTestnet":
          return "Bitcoin (Testnet)";
        case "EthereumRopsten":
          return "Ethereum (Ropsten)";
        case "EthereumGoerli":
        return "Ethereum (Goerli)";
        case "BinanceSmartChain":
          return "Binance Smart Chain";
        case "Polygon":
          return "Polygon";
        case "Avalanche":
          return "Avalanche C-Chain";
        default:
          return this.blockchain;
      }
    },
    messageToSign: function() {
      return sha256(this.blockchain + '(' + this.destination + ')');
    },
    isSignatureValid: function() {
      if ( !this.isSignaturePresent ) return false;

      const curve = new ec('secp256k1');
      var pubKey_obj = curve.keyFromPublic(this.$parent.pubKey, 'hex');
      const msg_obj = Buffer.from(this.messageToSign, "utf-8");

      return curve.verify(msg_obj, this.currenctSignature, pubKey_obj);
    },
  },
  watch: {
    destination: function(newVal) {
      this.updateErrors();
      this.$parent.isAllDestinationsSigned();
    },
    currenctSignature: function(newVal) {
      this.updateErrors();
      this.$parent.isAllDestinationsSigned();
    },
  },
  mounted() {
    this.destination = this.destinationAddress;
    this.currenctSignature = this.signature;
    this.errors = this.initErrors;
  }
}

</script>
