<template>
  <fragment>
    <img :src="require('images/new/approved-icon.svg')" v-if="showIcon && addressVerified" class="qr-copy mt-0 mt-md-1" alt="Address Verified"></img>
    <img :src="require('images/new/rejected-icon.svg')" v-if="showIcon && !addressVerified" class="qr-copy mt-0 mt-md-1" alt="Address Verified"></img>
  </fragment>
</template>

<script>
import { ec } from "elliptic";
import { sha256 } from "js-sha256";
import { eventBus } from "../utils/event_bus.js";

export default {
  name: "AddressValidationStatusShield",
  props: {
    blockchain: {
      type: String,
      required: true,
    },
    publicKey: {
      type: String,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    },
    signature: {
      type: String,
      required: true,
    },
  },
  data: function() {
    return {
      currentSignature: null,
      showIcon: false,
    };
  },
  computed: {
    messageToSign: function() {
      return sha256(this.blockchain + "(" + this.destination + ")");
    },
    addressVerified: function() {
      try {
        const curve = new ec("secp256k1");
        const pubKey_obj = curve.keyFromPublic(this.publicKey, "hex");
        const msg_obj = Buffer.from(this.messageToSign, "utf-8");
        return curve.verify(msg_obj, this.signature, pubKey_obj);
      } catch (e) {
        return false;
      }
    }
  },
  mounted() {
    const vm = this;
    vm.currentSignature = vm.signature;

    eventBus.$on(`wallet-siganture-changed-${vm.destination}`, (newSignature) => {
      vm.currentSignature = newSignature;
    });
  }
};
</script>
